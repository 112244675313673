import CreateTravelForm from "../../../components/Forms/FormsSteppers/Travels/CreateTravelForm";
import { TravelCrudProvider } from "../../../context/TravelCrudContext"


const TravelsCreate = () => {
    return (
        <TravelCrudProvider>
            <CreateTravelForm />
        </TravelCrudProvider>
    )
}

export default TravelsCreate;