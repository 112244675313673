import clsx from "clsx";
import { useEffect, useState } from "react";
import useAxios from "../hooks/useAxios";
import { Modal } from "react-bootstrap";

const TravelExceptionToggleComponent = ({ value }) => {

    const [hasTravelException, setHasTravelException] = useState(false);

    const [showUlimitedModal, setShowUlimitedModal] = useState(false);

    const [{ data, loading }, toggleException] = useAxios({ url: `travel-exceptions/${value?.id}`, method: 'PUT' }, { manual: true, useCache: false });

    useEffect(() => {
        setHasTravelException(value?.travelException ? true : false)
    }, [value])

    const toggleTravelException = () => {

        if (!hasTravelException) {
            setShowUlimitedModal(true);
            return;
        }

        toggleException().then(() => {
            setHasTravelException(old => !old);
        });
    }

    const handleUnlimited = (valor) => {
        toggleException({ data: { unlimited: valor } }).then(() => {
            setHasTravelException(old => !old);
            setShowUlimitedModal(false);
        });
    }

    return (
        <div>


            <button disabled={loading} onClick={toggleTravelException} className={clsx(["btn"], {
                'btn-success': hasTravelException,
                'btn-danger': !hasTravelException,
            })}>
                {
                    loading ?
                        'Cargando'
                        :
                        hasTravelException ?
                            'Activa'
                            :
                            'Inactiva'
                }
            </button>
            <Modal show={showUlimitedModal} onHide={() => setShowUlimitedModal(false)}>
                <Modal.Header closeButton>
                    <Modal.Title>¿Excepción Ilimitada?</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <p>
                        Si desea que la excepción sea ilimitada pulse la opción <b>SI</b> de lo contrario pulse en <b>No</b>
                    </p>
                    <div>
                        <button className="btn btn-primary" onClick={() => handleUnlimited('no')}>
                            No
                        </button>
                        <button className="btn btn-primary ms-3" onClick={() => handleUnlimited('si')}>
                            Si
                        </button>
                    </div>
                </Modal.Body>
                <Modal.Footer>
                    <button className="btn btn-secondary" onClick={() => setShowUlimitedModal(false)}>
                        Cancelar
                    </button>
                </Modal.Footer>
            </Modal>
        </div>
    )
}

export default TravelExceptionToggleComponent;