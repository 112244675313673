import { useEffect, useState } from "react";
import { Link, useSearchParams } from "react-router-dom";
import TravelColumns from "../../../components/CustomTable/Columns/TravelColumns";
import CustomTable from "../../../components/CustomTable/CustomTable";
import { useFeedBack } from "../../../context/FeedBackContext";
import useAxios from "../../../hooks/useAxios";
import useUserTravels from "../../../hooks/useUserTravels";
import MyTravelsColumns from "../../../components/CustomTable/Columns/MyTravelsColumns";

const MyTravels = () => {

    let [searchParams, setSearchParams] = useSearchParams();

    const { setCustomAlert, setLoading } = useFeedBack();

    const [filters, setFilters] = useState({
        page: 1,
        start: '',
        end: '',
        id: '',
        type: ''
    });

    const [selectedValues, setSelectedValues] = useState([]);

    const [selectAll, setSelectAll] = useState(false);

    const [{ userTravels: records, total, numberOfPages, loading }, getRecords] = useUserTravels({ params: { ...filters }, options: { useCache: false } });

    const [{ error: deleteError, loading: deleteLoading }, deleteRecord] = useAxios({ method: 'DELETE' }, { manual: true, useCache: false });

    useEffect(() => {
        var defaultFilters = { ...filters };
        Object.keys(filters).map((keyName) => {
            if (searchParams.get(keyName)) {
                defaultFilters[keyName] = searchParams.get(keyName) ?? '';
            }
        });
        setFilters(defaultFilters);
    }, [])

    useEffect(() => {
        getRecords({
            params: filters
        });

        setSearchParams(filters);
    }, [filters])

    useEffect(() => {
        setLoading?.({
            show: deleteLoading,
            message: 'Eliminando Registros'
        })
    }, [deleteLoading])

    useEffect(() => {
        if (deleteError) {
            setCustomAlert({
                title: 'error',
                severity: 'danger',
                message: 'Ha ocurrido un error al eliminar.',
                show: true
            });
        }
    }, [deleteError])

    useEffect(() => {
        if (selectAll) {
            setSelectedValues(records?.map?.((value) => value?.id))
        } else {
            setSelectedValues([])
        }
    }, [selectAll])

    const handleDelete = (value) => {
        deleteRecord({ url: `my-account/travels/${value?.id}` }).then((data) => {
            setCustomAlert({
                title: '¡Operación Exitosa!',
                severity: 'success',
                message: 'El registros ha sido eliminado exitosamente.',
                show: true
            });
            getRecords();
        })
    }

    const handleSelectALL = () => {
        setSelectAll((oldSelectAll) => !oldSelectAll);
    }

    const handleSelectValue = (selectedValue) => {
        const value = selectedValues?.includes(Number(selectedValue?.id));
        if (value) {
            const newValues = selectedValues?.filter(n => n !== Number(selectedValue?.id));
            setSelectedValues(newValues);
        } else {
            setSelectedValues((oldSelectedValues) => [...oldSelectedValues, Number(selectedValue?.id)])
        }
    }

    const handlePageChange = (page) => {
        if (page <= numberOfPages && page > 0) {
            setFilters((oldFilters) => {
                return {
                    ...oldFilters,
                    page: page
                }
            })
        }
    }

    const handleDeleteSelected = () => {
        deleteRecord({ url: `my-account/travels/multiple`, data: { ids: selectedValues } }).then((data) => {
            setCustomAlert({
                title: '¡Operación Exitosa!',
                severity: 'success',
                message: 'Los registros han sido eliminados exitosamente.',
                show: true
            })
            setSelectedValues([]);
            getRecords();
        });
    }

    const handleChange = (e) => {
        setFilters((oldFilters) => {
            return {
                ...oldFilters,
                [e.target.name]: e.target.value,
                page: 1
            }
        })
    }

    return (
        <div>
            <div className="my-4 justify-content-end d-flex">
                <Link to={"/solicitar-viaje"} className="btn btn-primary">
                    Crear Solicitud
                </Link>
            </div>

            <div className="row">
                <div className="col-md-3">
                    <div className="card p-4">
                        <div className="form-group">
                            <label className="text-primary">Numero</label>
                            <input
                                type="text"
                                className="form-control"
                                value={filters?.id}
                                onChange={handleChange}
                                name="id"
                                placeholder="Numero de solicitud"
                            />
                        </div>
                    </div>
                </div>
                <div className="col-md-3">
                    <div className="card p-4">
                        <div className="form-group">
                            <label className="text-primary">Tipo</label>
                            <select name="type" className="form-control" value={filters?.type} onChange={handleChange}>
                                <option value="">Seleccione un opción</option>
                                <option value="travel">Viaje</option>
                                <option value="accommodation">Hospedaje</option>
                                <option value="taxi">Taxi</option>
                            </select>
                        </div>
                    </div>
                </div>
                <div className="col-md-6">
                    <div className="card p-4">
                        <label className="text-primary">Fecha de creación</label>
                        <div className="row">
                            <div className="col-md-6">
                                <div className="form-group">
                                    <label className="text-primary">Desde:</label>
                                    <input
                                        type="date"
                                        className="form-control"
                                        value={filters?.start}
                                        onChange={handleChange}
                                        name="start"
                                    />
                                </div>
                            </div>
                            <div className="col-md-6">
                                <div className="form-group">
                                    <label className="text-primary">Hasta:</label>
                                    <input
                                        type="date"
                                        className="form-control"
                                        value={filters?.end}
                                        onChange={handleChange}
                                        name="end"
                                    />
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            <CustomTable
                onDeleteSelected={handleDeleteSelected}
                onSelectValue={handleSelectValue}
                onSelectAll={handleSelectALL}
                loading={loading}
                selectAll={selectAll}
                title={'Solicitudes'}
                entity={"travels"}
                updatePath={'/mi-cuenta/mis-viajes'}
                updateOptionString={'Ver Detalles'}
                onDelete={handleDelete}
                canDelete
                selectedValues={selectedValues}
                pages={numberOfPages}
                total={total}
                values={records}
                currentPage={filters?.page}
                collumns={MyTravelsColumns}
                changePage={handlePageChange}
            />
        </div>
    )
}

export default MyTravels;