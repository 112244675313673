import { Dropdown } from "react-bootstrap";
import { mainPermissions } from "../util/MenuLinks";
import UserHavePermission from "../util/userHavePermissions";
import useAxios from "../hooks/useAxios";
import { useEffect } from "react";
import { useFeedBack } from "../context/FeedBackContext";

const TravelExceptionsGlobalActions = ({ onUpdateException, selectedValues }) => {

    const { setLoading, setCustomAlert } = useFeedBack()

    const [{ data, loading }, toggleException] = useAxios({ url: `travel-exceptions/multiple-update`, method: 'PUT' }, { manual: true, useCache: false });

    useEffect(() => {
        setLoading?.({
            show: loading,
            message: 'Actualizando'
        })
    }, [loading])

    const handleUpdateExceptions = (action) => {
        toggleException({
            data: {
                userIds: selectedValues,
                action
            }
        })?.then(() => {
            onUpdateException?.();
            setCustomAlert({
                title: '¡Operación Exitosa!',
                severity: 'success',
                message: 'Los registros han sido actualizados exitosamente.',
                show: true
            });
        });
    }

    return (
        <Dropdown className="dropdown ms-auto text-right">
            <Dropdown.Toggle
                variant=""
                className="btn-link i-false"
                data-toggle="dropdown"
            >
                <svg
                    width="24px"
                    height="24px"
                    viewBox="0 0 24 24"
                    version="1.1"
                >
                    <g
                        stroke="none"
                        strokeWidth={1}
                        fill="none"
                        fillRule="evenodd"
                    >
                        <rect x={0} y={0} width={24} height={24} />
                        <circle fill="#000000" cx={5} cy={12} r={2} />
                        <circle fill="#000000" cx={12} cy={12} r={2} />
                        <circle fill="#000000" cx={19} cy={12} r={2} />
                    </g>
                </svg>
            </Dropdown.Toggle>
            <Dropdown.Menu className="dropdown-menu dropdown-menu-right">
                {
                    UserHavePermission(mainPermissions?.exceptions?.[0]) &&
                    <>
                        <Dropdown.Item onClick={() => handleUpdateExceptions?.('activar')}>
                            Activar Excepciones
                        </Dropdown.Item>
                        <Dropdown.Item onClick={() => handleUpdateExceptions?.('desactivar')}>
                            Desactivar Excepciones
                        </Dropdown.Item>
                    </>
                }
            </Dropdown.Menu>
        </Dropdown>
    )
}

export default TravelExceptionsGlobalActions;