import { useTravelCrud } from "../../../../context/TravelCrudContext";
import AsyncSelect from "react-select/async";
import mapValues from "../../../../util/mapValues";
import handleLoadSelectOptions from "../../../../util/loadSelectValues";
import useCommunes from "../../../../hooks/useCommunes";
import { useState, useEffect } from "react";
import travelImage from "../../../../images/travel.png";
import grayTravelImage from "../../../../images/travel-gris.png";
import update from "immutability-helper";
import useCostCenters from "../../../../hooks/useCostCenters";
import readXlsxFile from 'read-excel-file'
import useAxios from "../../../../hooks/useAxios";
import plantillaExcel from "../../../../documents/plantilla-personas-para-viajes.xlsx";

const StepTwo = () => {
  const { data, setData, currentStep, setCurrentStep, minDates } = useTravelCrud();

  const [{ communes, loading: communesLoading }, getCommunes] = useCommunes({
    options: { manual: true, useCache: false },
  });

  const [{ }, getCostCenter] = useAxios({ method: 'GET' }, { manual: true, useCache: true });

  const [{ costCenters, loading: costCentersLoading }, getCostCenters] =
    useCostCenters({ options: { manual: true, useCache: false } });

  const [canContinue, setCanContinue] = useState(false);

  useEffect(() => {
    if (data.travelStart && !data.accommodationStart)
      setData((oldData) => ({
        ...oldData,
        accommodationStart: data.travelStart,
      }));
    if (data.travelStart && !data.taxiStart)
      setData((oldData) => ({ ...oldData, taxiStart: data.travelStart }));

    if (data.travelEnd && !data.accommodationEnd)
      setData((oldData) => ({ ...oldData, accommodationEnd: data.travelEnd }));
    if (data.travelEnd && !data.taxiEnd)
      setData((oldData) => ({ ...oldData, taxiEnd: data.travelEnd }));
  }, [data.travelStart, data.travelEnd]);

  useEffect(() => {
    if (data.travelStartFromCommune && !data.travelEndToCommune)
      setData((oldData) => ({
        ...oldData,
        travelEndToCommune: data.travelStartFromCommune,
      }));

    if (data.travelStartToCommune && !data.travelEndFromCommune)
      setData((oldData) => ({
        ...oldData,
        travelEndFromCommune: data.travelStartToCommune,
      }));

    if (data.travelStartToCommune && !data.accommodationCommune)
      setData((oldData) => ({
        ...oldData,
        accommodationCommune: data.travelStartToCommune,
      }));

    if (data.travelStartFromCommune && !data.taxiStartCommune)
      setData((oldData) => ({
        ...oldData,
        taxiStartCommune: data.travelStartFromCommune,
      }));

    if (data.travelStartToCommune && !data.taxiEndCommune)
      setData((oldData) => ({
        ...oldData,
        taxiEndCommune: data.travelStartToCommune,
      }));
  }, [data.travelStartFromCommune, data.travelStartToCommune]);

  useEffect(() => {
    if (data?.travel === "si") {
      // Si viaja
      if (
        data.travelModality &&
        data.travelStart &&
        data.travelStartHour &&
        data.travelStartFromCommune &&
        data.travelStartToCommune &&
        data.travelBaggage &&
        data.travelPersons.length > 0
      ) {
        // Debe llenar todos los datos del vieaje de ida
        if (data?.travelBack) {
          //Si viaja de vuelta
          if (
            data.travelEnd &&
            data.travelEndHour &&
            data.travelEndFromCommune &&
            data.travelEndToCommune
          ) {
            //Debe llenar todos los datos de vuelta para poder continuar
            setCanContinue(true);
          } else {
            setCanContinue(false);
          }
        } else {
          // Si no viaja de vuelta puede continuar
          setCanContinue(true);
        }
      } else {
        setCanContinue(false);
      }
    } else {
      // Si no viaja puede continuar
      setCanContinue(true);
    }
  }, [data]);

  const handleChange = (e) => {
    setData((oldData) => {
      return {
        ...oldData,
        [e.target.name]: e.target.value,
      };
    });
  };

  const handleArrayChange = (e, index, arrayName) => {
    var newArrayValues = [];
    if (e.target.name === "images") {
      newArrayValues = update(data?.[arrayName], {
        [index]: { $set: e.target.files[0] },
      });
    } else {
      newArrayValues = update(data?.[arrayName], {
        [index]: {
          [e.target.name]: {
            $set: e.target.type === "file" ? e.target.files[0] : e.target.value,
          },
        },
      });
    }
    setData((oldData) => {
      return {
        ...oldData,
        [arrayName]: newArrayValues,
      };
    });
  };

  const removeFromArray = (index, arrayName) => {
    data?.[arrayName]?.splice(index, 1);

    setData((oldData) => {
      return {
        ...oldData,
        [arrayName]: data?.[arrayName],
      };
    });
  };

  const handleRead = (e) => {
    readXlsxFile(e.target.files[0]).then(async (rows) => {
      var response = null;
      for (let index = 0; index < rows.length; index++) {
        const row = rows[index];
        if (index > 0) {
          try {
            if (!response) {
              response = await getCostCenter({ url: `cost-centers/get-by-code`, params: { code: row?.[4] } });
              console.log('consulte la primera');
            }
            if (index > 1 && rows?.[index - 1]?.[4] && response?.data?.code != rows?.[index - 1]?.[4]) {
              response = await getCostCenter({ url: `cost-centers/get-by-code`, params: { code: row?.[4] } });
              console.log('consulte la segunda');
            }

            setData((oldData) => {
              return {
                ...oldData,
                travelPersons: [
                  ...oldData?.travelPersons,
                  {
                    name: row[0],
                    rut: row[1],
                    email: row[2],
                    phoneNumber: row[3],
                    costCenter: { label: response?.data?.name, value: response?.data?.id },
                    birthdate: row[5],
                    chargeForFormulating: row[6]
                  }
                ]
              }
            })

          } catch (error) {

          }
        }
      }
    })
  }

  return (
    <div className="container">
      <div className="card col-md-12 animate__animated animate__fadeInUp">
        <div className="card-header">
          <h4>Información sobre el Viaje</h4>
        </div>
        <div className="card-body">
          <h3>¿Necesitas Viajar?</h3>
          <div className="row align-items-center">
            <div className="col-md-6">
              <div className="text-center">
                <label
                  onClick={() =>
                    handleChange({ target: { name: "travel", value: "no" } })
                  }
                  htmlFor="togglesome"
                  className="text-center mt-5 animate__animated animate__fadeIn"
                  style={{ width: "fit-content", cursor: "pointer" }}
                >
                  <img src={grayTravelImage} style={{ width: "180px" }} />
                  <h3>
                    <input
                      className="mx-2"
                      type="radio"
                      checked={data.travel === "no"}
                      onChange={() => null}
                    />
                    No
                  </h3>
                </label>
              </div>
            </div>
            <div className="col-md-6">
              <div className="text-center">
                <label
                  onClick={() =>
                    handleChange({ target: { name: "travel", value: "si" } })
                  }
                  htmlFor="togglesome"
                  className="text-center mt-5 animate__animated animate__fadeIn"
                  style={{ width: "fit-content", cursor: "pointer" }}
                >
                  <img src={travelImage} style={{ width: "180px" }} />
                  <h3>
                    <input
                      className="mx-2"
                      type="radio"
                      checked={data?.travel === "si"}
                      onChange={() => null}
                    />
                    Si
                  </h3>
                </label>
              </div>
            </div>
            {data?.travel === "si" && (
              <div className="col-md-12 animate__animated animate__fadeInLeft">
                <div className="row align-items-center">
                  <div className="col-md-12 mb-3">
                    <label>Modalidad del viaje</label>
                    <select
                      onChange={handleChange}
                      className="form-control"
                      name="travelModality"
                      value={data?.travelModality}
                    >
                      <option value="terrestre">Terrestre</option>
                      <option value="aerea">Aérea</option>
                    </select>
                  </div>
                  {data.travelModality === "aerea" && (
                    <div className="form-group mb-5 col-md-12">
                      <label htmlFor="">¿LLeva equipaje de bodega?</label>
                      <select
                        name="travelBaggage"
                        value={data.travelBaggage}
                        onChange={handleChange}
                        className="form-control"
                      >
                        <option value="si">si</option>
                        <option value="no">no</option>
                      </select>
                    </div>
                  )}
                  <div className="form-group mb-5 col-md-12">
                    <h3 className="text-center">Viaje de ida</h3>
                    <div className="row">
                      <div className="col-md-6 mb-3">
                        <label>Fecha de salida</label>
                        <input
                          type="date"
                          min={data?.travelModality === 'aerea' ? minDates?.travelAir : minDates?.travelGround}
                          name="travelStart"
                          onChange={handleChange}
                          value={data.travelStart}
                          required
                          className="form-control"
                        />
                      </div>
                      <div className="col-md-6 mb-3">
                        <label>Hora de salida</label>
                        <input
                          type="time"
                          name="travelStartHour"
                          onChange={handleChange}
                          value={data.travelStartHour}
                          required
                          className="form-control"
                        />
                      </div>
                    </div>
                    <div className="row mt-2">
                      <div className="col-md-6 form-group">
                        <label>¿Viaja desde?</label>
                        <AsyncSelect
                          onFocus={() => {
                            getCommunes();
                          }}
                          value={data?.travelStartFromCommune}
                          defaultOptions={mapValues(communes)}
                          isLoading={communesLoading}
                          loadOptions={(e) =>
                            handleLoadSelectOptions(e, getCommunes)
                          }
                          placeholder="buscar..."
                          onChange={(e) => {
                            handleChange({
                              target: {
                                value: e,
                                name: "travelStartFromCommune",
                              },
                            });
                          }}
                        />
                      </div>
                      <div className="col-md-6 form-group">
                        <label>¿Hasta?</label>
                        <AsyncSelect
                          onFocus={() => {
                            getCommunes();
                          }}
                          value={data?.travelStartToCommune}
                          defaultOptions={mapValues(communes)}
                          isLoading={communesLoading}
                          loadOptions={(e) =>
                            handleLoadSelectOptions(e, getCommunes)
                          }
                          placeholder="buscar..."
                          onChange={(e) => {
                            handleChange({
                              target: {
                                value: e,
                                name: "travelStartToCommune",
                              },
                            });
                          }}
                        />
                      </div>
                    </div>
                  </div>
                  {data?.travelBack ? (
                    <div className="animate__animated animate__slideInDown">
                      <div className="form-group mb-3 col-md-12">
                        <h3 className="text-center">Viaje de vuelta</h3>
                        <div className="row">
                          <div className="col-md-6 mb-3">
                            <label>Fecha de salida</label>
                            <input
                              type="date"
                              name="travelEnd"
                              onChange={handleChange}
                              value={data.travelEnd}
                              required
                              className="form-control"
                              min={data?.travelStart ? data?.travelStart : data?.travelModality === 'aerea' ? minDates?.travelAir : minDates?.travelGround}
                            />
                          </div>
                          <div className="col-md-6 mb-3">
                            <label>Hora de salida</label>
                            <input
                              type="time"
                              name="travelEndHour"
                              onChange={handleChange}
                              value={data.travelEndHour}
                              required
                              className="form-control"
                            />
                          </div>
                        </div>
                        <div className="row mt-2">
                          <div className="col-md-6 form-group">
                            <label>¿Viaja desde?</label>
                            <AsyncSelect
                              onFocus={() => {
                                getCommunes();
                              }}
                              value={data?.travelEndFromCommune}
                              defaultOptions={mapValues(communes)}
                              isLoading={communesLoading}
                              loadOptions={(e) =>
                                handleLoadSelectOptions(e, getCommunes)
                              }
                              placeholder="buscar..."
                              onChange={(e) => {
                                handleChange({
                                  target: {
                                    value: e,
                                    name: "travelEndFromCommune",
                                  },
                                });
                              }}
                            />
                          </div>
                          <div className="col-md-6 form-group">
                            <label>¿Hasta?</label>
                            <AsyncSelect
                              onFocus={() => {
                                getCommunes();
                              }}
                              value={data?.travelEndToCommune}
                              defaultOptions={mapValues(communes)}
                              isLoading={communesLoading}
                              loadOptions={(e) =>
                                handleLoadSelectOptions(e, getCommunes)
                              }
                              placeholder="buscar..."
                              onChange={(e) => {
                                handleChange({
                                  target: {
                                    value: e,
                                    name: "travelEndToCommune",
                                  },
                                });
                              }}
                            />
                          </div>
                        </div>
                      </div>
                      <div className="text-center mt-5">
                        <h3 className="text-danger">
                          ¿Cancelar Viaje de vuelta?
                        </h3>
                        <h5
                          onClick={() => {
                            setData((oldData) => {
                              return {
                                ...oldData,
                                travelBack: !oldData?.travelBack,
                              };
                            });
                          }}
                          className="animate__animated animate__pulse animate__infinite text-danger"
                          style={{ cursor: "pointer" }}
                        >
                          Pulsa aquí
                        </h5>
                      </div>
                    </div>
                  ) : (
                    <div className="text-center animate__animated animate__slideInUp">
                      <h3>¿Necesita viajar de vuelta?</h3>
                      <h5
                        onClick={() => {
                          setData((oldData) => {
                            return {
                              ...oldData,
                              travelBack: !oldData?.travelBack,
                            };
                          });
                        }}
                        className="animate__animated animate__pulse animate__infinite text-primary"
                        style={{ cursor: "pointer" }}
                      >
                        Pulsa aquí
                      </h5>
                    </div>
                  )}

                  <div className="col-md-12 my-5">
                    <h3 className="text-center">Personas que van a viajar</h3>
                    <div className="text-center">
                      <a href={plantillaExcel} download="plantilla-personas-para-viajes.xlsx">
                        Descargar plantilla
                      </a>
                      <br />
                      <label htmlFor="upload-file" className="btn btn-success">
                        <input id="upload-file" accept="application/vnd.openxmlformats-officedocument.spreadsheetml.sheet, application/vnd.ms-excel" type="file" onChange={handleRead} className="d-none" />
                        Cargar desde archivo
                      </label>
                    </div>
                    <div className="table-responsive">
                      <table className="table" style={{ minWidth: "600px" }}>
                        <tbody>
                          {data?.travelPersons?.map((person, i) => {
                            return (
                              <tr key={i}>
                                <td>
                                  <label>
                                    <small className="text-primary">
                                      Nombre{" "}
                                      <span className="text-danger">*</span>
                                    </small>
                                  </label>
                                  <input
                                    onChange={(e) =>
                                      handleArrayChange(e, i, "travelPersons")
                                    }
                                    value={person.name}
                                    name="name"
                                    type="text"
                                    className="form-control"
                                    placeholder="Nombre..."
                                  />
                                </td>
                                <td>
                                  <label>
                                    <small className="text-primary">
                                      Rut <span className="text-danger">*</span>
                                    </small>
                                  </label>
                                  <input
                                    onChange={(e) =>
                                      handleArrayChange(e, i, "travelPersons")
                                    }
                                    value={person.rut}
                                    name="rut"
                                    type="text"
                                    className="form-control"
                                    placeholder="Rut..."
                                  />
                                </td>
                                <td>
                                  <label>
                                    <small className="text-primary">
                                      Email{" "}
                                      <span className="text-danger">*</span>
                                    </small>
                                  </label>
                                  <input
                                    onChange={(e) =>
                                      handleArrayChange(e, i, "travelPersons")
                                    }
                                    value={person.email}
                                    name="email"
                                    type="text"
                                    className="form-control"
                                    placeholder="Email..."
                                  />
                                </td>
                                <td>
                                  <label>
                                    <small className="text-primary">
                                      Teléfono{" "}
                                      <span className="text-danger">*</span>
                                    </small>
                                  </label>
                                  <input
                                    onChange={(e) =>
                                      handleArrayChange(e, i, "travelPersons")
                                    }
                                    value={person.phoneNumber}
                                    name="phoneNumber"
                                    type="text"
                                    className="form-control"
                                    placeholder="Telefono..."
                                  />
                                </td>
                                <td>
                                  <label>
                                    <small className="text-primary">
                                      Centro de costo{" "}
                                      <span className="text-danger">*</span>
                                    </small>
                                  </label>
                                  <AsyncSelect
                                    onFocus={() => {
                                      getCostCenters();
                                    }}
                                    defaultOptions={mapValues(costCenters)}
                                    value={person.costCenter}
                                    isLoading={costCentersLoading}
                                    loadOptions={(e) =>
                                      handleLoadSelectOptions(e, getCostCenters)
                                    }
                                    placeholder="buscar..."
                                    onChange={(e) =>
                                      handleArrayChange(
                                        {
                                          target: {
                                            value: e,
                                            name: "costCenter",
                                          },
                                        },
                                        i,
                                        "travelPersons"
                                      )
                                    }
                                  />
                                </td>
                                <td>
                                  <label>
                                    <small className="text-primary">
                                      Fecha de nacimiento{" "}
                                      <span className="text-danger">*</span>
                                    </small>
                                  </label>
                                  <input
                                    onChange={(e) =>
                                      handleArrayChange(e, i, "travelPersons")
                                    }
                                    value={person.birthdate}
                                    name="birthdate"
                                    type="date"
                                    className="form-control"
                                    placeholder="fecha de nacimiento..."
                                  />
                                </td>
                                <td style={{ width: 80 }}>
                                  <label>
                                    <small className="text-primary">
                                      Cobro por Formular{" "}
                                      <span className="text-danger">*</span>
                                    </small>
                                  </label>
                                  <select className="form-control"
                                    value={person?.chargeForFormulating}
                                    name="chargeForFormulating"
                                    onChange={(e) =>
                                      handleArrayChange(e, i, "travelPersons")
                                    }
                                  >
                                    <option value="no">No</option>
                                    <option value="si">Si</option>
                                  </select>
                                </td>
                                <td>
                                  <div className="d-flex align-items-center">
                                    <button
                                      onClick={() =>
                                        removeFromArray(i, "travelPersons")
                                      }
                                      title="Remover Persona"
                                      className="btn btn-xs btn-danger"
                                    >
                                      X
                                    </button>
                                  </div>
                                </td>
                              </tr>
                            );
                          })}
                          <tr>
                            <td colSpan={7}>
                              <div className="text-center">
                                <button
                                  onClick={() => {
                                    setData((oldData) => {
                                      return {
                                        ...oldData,
                                        travelPersons: [],
                                      };
                                    });
                                  }}
                                  className="btn btn-xs btn-danger"
                                >
                                  Remover Todas
                                </button>
                                <button
                                  onClick={() => {
                                    setData((oldData) => {
                                      return {
                                        ...oldData,
                                        travelPersons: [
                                          ...oldData?.travelPersons,
                                          {
                                            name: "",
                                            rut: "",
                                            email: "",
                                            phoneNumber: "",
                                            costCenter: "",
                                            birthdate: "",
                                            chargeForFormulating: 'no'
                                          },
                                        ],
                                      };
                                    });
                                  }}
                                  className="btn btn-xs btn-primary"
                                >
                                  Agregar Persona
                                </button>
                              </div>
                            </td>
                          </tr>
                        </tbody>
                      </table>
                    </div>
                  </div>
                  <div className="col-md-12 mt-3">
                    <label htmlFor="">Observaciones</label>
                    <textarea
                      name="travelObservations"
                      value={data?.travelObservations}
                      style={{
                        minHeight: "150px",
                      }}
                      className="form-control"
                      onChange={handleChange}
                    />
                  </div>
                </div>
              </div>
            )}
          </div>
        </div>
        <div className="card-footer text-end">
          <button
            disabled={!canContinue}
            type="button"
            className="btn btn-primary mx-1"
            onClick={() => {
              setCurrentStep((oldStep) => oldStep + 1);
            }}
          >
            Siguiente
          </button>
        </div>
      </div>
    </div>
  );
};

export default StepTwo;
