import { useEffect, useState } from "react";
import { useTravelCrud } from "../../../../context/TravelCrudContext";
import taxiImage from "../../../../images/taxi.png";
import grayTaxiImage from "../../../../images/taxi-gris.png";
import AsyncSelect from "react-select/async";
import mapValues from "../../../../util/mapValues";
import handleLoadSelectOptions from "../../../../util/loadSelectValues";
import useCommunes from "../../../../hooks/useCommunes";
import update from "immutability-helper";
import useCostCenters from "../../../../hooks/useCostCenters";
import Map from "../../../googlemaps/Map";
import AutoCompleteInput from "../../../googlemaps/AutoCompleteInput";
import useAxios from "../../../../hooks/useAxios";
import readXlsxFile from 'read-excel-file';
import plantillaExcel from "../../../../documents/plantilla-personas-para-taxis.xlsx";
import { Modal } from "react-bootstrap";

const StepFour = () => {

  const { data, setData, currentStep, setCurrentStep, minDates } = useTravelCrud();

  const [{ communes, loading: communesLoading }, getCommunes] = useCommunes({
    options: { manual: true, useCache: false },
  });

  const [showAlert, setShowAlert] = useState(false);

  const [{ }, getCostCenter] = useAxios({ method: 'GET' }, { manual: true, useCache: true });

  const [{ costCenters, loading: costCentersLoading }, getCostCenters] =
    useCostCenters({ options: { manual: true, useCache: false } });

  const [canContinue, setCanContinue] = useState(false);

  useEffect(() => {
    if (data.taxiStart && !data.travelStart) setData(oldData => ({ ...oldData, travelStart: data.taxiStart }))
    if (data.taxiStart && !data.accommodationStart) setData(oldData => ({ ...oldData, accommodationStart: data.taxiStart }))

    if (data.taxiEnd && !data.travelEnd) setData(oldData => ({ ...oldData, travelEnd: data.taxiEnd }));
    if (data.taxiEnd && !data.accommodationEnd) setData(oldData => ({ ...oldData, accommodationEnd: data.taxiEnd }));

  }, [data.taxiStart, data.taxiEnd]);

  useEffect(() => {

    if (data.taxiStartCommune && !data.travelStartFromCommune) setData(oldData => ({ ...oldData, travelStartFromCommune: data.taxiStartCommune }))

    if (data.taxiStartCommune && !data.travelEndToCommune) setData(oldData => ({ ...oldData, travelEndToCommune: data.taxiStartCommune }))

    if (data.taxiEndCommune && !data.travelStartToCommune) setData(oldData => ({ ...oldData, travelStartToCommune: data.taxiEndCommune }))

    if (data.taxiEndCommune && !data.travelEndFromCommune) setData(oldData => ({ ...oldData, travelEndFromCommune: data.taxiEndCommune }))

    if (data.taxiEndCommune && !data.accommodationCommune) setData(oldData => ({ ...oldData, accommodationCommune: data.taxiEndCommune }))

  }, [data.taxiStartCommune, data.taxiEndCommune])

  useEffect(() => {
    if (data?.taxi === "si") {
      // Si viaja
      if (
        data.taxiStart &&
        data.taxiStartHour &&
        data.taxiPersons.length > 0 &&
        data.taxiStartFrom &&
        data.taxiStartFromPoint &&
        data.taxiStartTo &&
        data.taxiStartToPoint
      ) {
        // Debe llenar todos los datos del vieaje de ida
        if (data?.taxiBack) {
          //Si viaja de vuelta
          if (
            data.taxiEnd &&
            data.taxiEndHour &&
            data.taxiEndFrom &&
            data.taxiEndFromPoint &&
            data.taxiEndTo &&
            data.taxiEndToPoint
          ) {
            //Debe llenar todos los datos de vuelta para poder continuar
            setCanContinue(true);
          } else {
            setCanContinue(false);
          }
        } else {
          // Si no viaja de vuelta puede continuar
          setCanContinue(true);
        }
      } else {
        setCanContinue(false);
      }
    } else {
      // Si no viaja puede continuar
      setCanContinue(true);
    }
  }, [data]);

  const handleChange = (e) => {

    if (e.target.latLng) {
      setData((oldData) => {
        return {
          ...oldData,
          [`${e.target.name}Point`]: e.target.latLng,
        };
      });
    }

    setData((oldData) => {
      return {
        ...oldData,
        [e.target.name]: e.target.value,
      };
    });
  };

  const handleArrayChange = (e, index, arrayName) => {
    var newArrayValues = [];
    if (e.target.name === "images") {
      newArrayValues = update(data?.[arrayName], {
        [index]: { $set: e.target.files[0] },
      });
    } else {
      newArrayValues = update(data?.[arrayName], {
        [index]: {
          [e.target.name]: {
            $set: e.target.type === "file" ? e.target.files[0] : e.target.value,
          },
        },
      });
    }
    setData((oldData) => {
      return {
        ...oldData,
        [arrayName]: newArrayValues,
      };
    });
  };

  const removeFromArray = (index, arrayName) => {
    data?.[arrayName]?.splice(index, 1);

    setData((oldData) => {
      return {
        ...oldData,
        [arrayName]: data?.[arrayName],
      };
    });
  };

  const handleRead = (e) => {
    readXlsxFile(e.target.files[0]).then(async (rows) => {
      var response = null;
      for (let index = 0; index < rows.length; index++) {
        const row = rows[index];
        if (index > 0) {
          try {
            if (!response) {
              response = await getCostCenter({ url: `cost-centers/get-by-code`, params: { code: row?.[4] } });
              console.log('consulte la primera');
            }
            if (index > 1 && rows?.[index - 1]?.[4] && response?.data?.code != rows?.[index - 1]?.[4]) {
              response = await getCostCenter({ url: `cost-centers/get-by-code`, params: { code: row?.[4] } });
              console.log('consulte la segunda');
            }

            setData((oldData) => {
              return {
                ...oldData,
                taxiPersons: [
                  ...oldData?.taxiPersons,
                  {
                    name: row[0],
                    rut: row[1],
                    email: row[2],
                    phoneNumber: row[3],
                    costCenter: { label: response?.data?.name, value: response?.data?.id },
                    subject: row[5],
                    chargeForFormulating: row[6]
                  }
                ]
              }
            })

          } catch (error) {

          }
        }
      }
    })
  }

  return (
    <div className="container">
      <div className="card col-md-12 animate__animated animate__fadeInUp p-4">
        <div className="card-header">
          <h4>Información sobre el servicio de taxi</h4>
        </div>
        <div className="card-body">
          <div className="row">
            <div className="form-group mb-1 col-md-12">
              <h3>¿Necesitas servicio de taxi?</h3>
              <div className="row align-items-center">
                <div className="col-md-6">
                  <div className="text-center">
                    <label
                      onClick={() =>
                        handleChange({ target: { name: "taxi", value: "no" } })
                      }
                      htmlFor="togglesome"
                      className="text-center mt-3 animate__animated animate__fadeIn"
                      style={{ width: "fit-content", cursor: "pointer" }}
                    >
                      <img src={grayTaxiImage} style={{ width: "200px" }} />
                      <h3>
                        <input
                          className="mx-2"
                          type="radio"
                          checked={data.taxi === "no"}
                          onChange={() => null}
                        />
                        No
                      </h3>
                    </label>
                  </div>
                </div>
                <div className="col-md-6">
                  <div className="text-center">
                    <label
                      onClick={() =>
                        handleChange({ target: { name: "taxi", value: "si" } })
                      }
                      htmlFor="togglesome"
                      className="text-center mt-3 animate__animated animate__fadeIn"
                      style={{ width: "fit-content", cursor: "pointer" }}
                    >
                      <img src={taxiImage} style={{ width: "200px" }} />
                      <h3>
                        <input
                          className="mx-2"
                          type="radio"
                          checked={data?.taxi === "si"}
                          onChange={() => null}
                        />
                        Si
                      </h3>
                    </label>
                  </div>
                </div>
                {data.taxi === "si" && (
                  <div className="col-md-12 mt-4 animate__animated animate__fadeInLeft">
                    <h3 className="text-center mb-3">Datos del Taxi</h3>
                    <div className="row">
                      <div className="col-md-6">
                        <div className="row">
                          <div className="col-md-6 mb-3">
                            <label>Fecha de ida</label>
                            <input
                              type="date"
                              name="taxiStart"
                              onChange={handleChange}
                              value={data.taxiStart}
                              min={minDates?.taxi}
                              required
                              className="form-control"
                            />
                          </div>
                          <div className="col-md-6 mb-3">
                            <label>Hora de ida</label>
                            <input
                              type="time"
                              name="taxiStartHour"
                              onChange={handleChange}
                              value={data.taxiStartHour}
                              required
                              className="form-control"
                            />
                          </div>
                          <div className="col-md-12 mb-3">
                            <label>Desde:</label>
                            <AutoCompleteInput
                              className="form-control"
                              name="taxiStartFrom"
                              value={data?.taxiStartFrom}
                              onChange={handleChange}
                              placeholder="Ej. Calle grande #7651.."
                            />
                          </div>
                          <div className="col-md-12 mb-3">
                            <label>Hasta:</label>
                            <AutoCompleteInput
                              className="form-control"
                              name="taxiStartTo"
                              value={data?.taxiStartTo}
                              onChange={handleChange}
                              placeholder="Ej. Calle grande #7651.."
                            />
                          </div>
                        </div>
                      </div>
                      <div className="col-md-6">
                        <Map
                          options={{
                            center: { lat: -33.445245, lng: -70.660581 },
                            zoom: 5
                          }}
                          markers={[data?.taxiStartFromPoint, data?.taxiStartToPoint]}
                          defaultPolygon={[data?.taxiStartFromPoint, data?.taxiStartToPoint]}
                        />
                      </div>
                      {data?.taxiBack ? (
                        <div className="animate__animated animate__slideInDown mt-5">
                          <div className="form-group mb-3 col-md-12">
                            <h3 className="text-center">Datos de regreso</h3>
                            <div className="row">
                              <div className="col-md-6">
                                <div className="row">
                                  <div className="col-md-6 mb-3">
                                    <label>Fecha de regreso</label>
                                    <input
                                      type="date"
                                      name="taxiEnd"
                                      onChange={handleChange}
                                      value={data.taxiEnd}
                                      required
                                      min={data?.taxiStart || minDates?.taxi || null}
                                      className="form-control"
                                    />
                                  </div>
                                  <div className="col-md-6 mb-3">
                                    <label>Hora de regreso</label>
                                    <input
                                      type="time"
                                      name="taxiEndHour"
                                      onChange={handleChange}
                                      value={data.taxiEndHour}
                                      required
                                      className="form-control"
                                    />
                                  </div>
                                  <div className="col-md-12 mb-3">
                                    <label>Desde:</label>
                                    <AutoCompleteInput
                                      className="form-control"
                                      name="taxiEndFrom"
                                      value={data?.taxiEndFrom}
                                      onChange={handleChange}
                                      placeholder="Ej. Calle grande #7651.."
                                    />
                                  </div>
                                  <div className="col-md-12 mb-3">
                                    <label>Hasta:</label>
                                    <AutoCompleteInput
                                      className="form-control"
                                      name="taxiEndTo"
                                      value={data?.taxiEndTo}
                                      onChange={handleChange}
                                      placeholder="Ej. Calle grande #7651.."
                                    />
                                  </div>
                                </div>
                              </div>
                              <div className="col-md-6">
                                <Map
                                  options={{
                                    center: { lat: -33.445245, lng: -70.660581 },
                                    zoom: 5
                                  }}
                                  markers={[data?.taxiEndFromPoint, data?.taxiEndToPoint]}
                                  defaultPolygon={[data?.taxiEndFromPoint, data?.taxiEndToPoint]}
                                />
                              </div>
                            </div>
                          </div>
                          <div className="text-center mt-5">
                            <h3 className="text-danger">
                              ¿Cancelar taxi de vuelta?
                            </h3>
                            <h5
                              onClick={() => {
                                setData((oldData) => {
                                  return {
                                    ...oldData,
                                    taxiBack: !oldData?.taxiBack,
                                  };
                                });
                              }}
                              className="animate__animated animate__pulse animate__infinite text-danger"
                              style={{ cursor: "pointer" }}
                            >
                              Pulsa aquÍ
                            </h5>
                          </div>
                        </div>
                      ) : (
                        <div className="text-center animate__animated animate__slideInUp mt-5">
                          <h3>¿Necesita taxi de vuelta?</h3>
                          <h5
                            onClick={() => {
                              setData((oldData) => {
                                return {
                                  ...oldData,
                                  taxiBack: !oldData?.taxiBack,
                                };
                              });
                            }}
                            className="animate__animated animate__pulse animate__infinite text-primary"
                            style={{ cursor: "pointer" }}
                          >
                            Pulsa aquÍ
                          </h5>
                        </div>
                      )}
                      <div className="col-md-12 mb-4 mt-5">
                        <h3 className="text-center">
                          Personas que se van a trasladar
                        </h3>
                        <div className="text-center">
                          <a href={plantillaExcel} download="plantilla-personas-para-taxis.xlsx">
                            Descargar plantilla
                          </a>
                          <br />
                          <label htmlFor="upload-file" className="btn btn-success">
                            <input id="upload-file" accept="application/vnd.openxmlformats-officedocument.spreadsheetml.sheet, application/vnd.ms-excel" type="file" onChange={handleRead} className="d-none" />
                            Cargar desde archivo
                          </label>
                        </div>
                        <div className="table-responsive">
                          <table
                            className="table"
                            style={{ minWidth: "600px" }}
                          >
                            <tbody>
                              {data?.taxiPersons?.map((person, i) => {
                                return (
                                  <tr key={i}>
                                    <td>
                                      <label>
                                        <small className="text-primary">
                                          Nombre{" "}
                                          <span className="text-danger">*</span>
                                        </small>
                                      </label>
                                      <input
                                        onChange={(e) =>
                                          handleArrayChange(e, i, "taxiPersons")
                                        }
                                        value={person.name}
                                        name="name"
                                        type="text"
                                        className="form-control"
                                        placeholder="Nombre..."
                                      />
                                    </td>
                                    <td>
                                      <label>
                                        <small className="text-primary">
                                          Rut{" "}
                                          <span className="text-danger">*</span>
                                        </small>
                                      </label>
                                      <input
                                        onChange={(e) =>
                                          handleArrayChange(e, i, "taxiPersons")
                                        }
                                        value={person.rut}
                                        name="rut"
                                        type="text"
                                        className="form-control"
                                        placeholder="Rut..."
                                      />
                                    </td>
                                    <td>
                                      <label>
                                        <small className="text-primary">
                                          Email{" "}
                                          <span className="text-danger">*</span>
                                        </small>
                                      </label>
                                      <input
                                        onChange={(e) =>
                                          handleArrayChange(e, i, "taxiPersons")
                                        }
                                        value={person.email}
                                        name="email"
                                        type="text"
                                        className="form-control"
                                        placeholder="Email..."
                                      />
                                    </td>
                                    <td>
                                      <label>
                                        <small className="text-primary">
                                          Teléfono{" "}
                                          <span className="text-danger">*</span>
                                        </small>
                                      </label>
                                      <input
                                        onChange={(e) =>
                                          handleArrayChange(e, i, "taxiPersons")
                                        }
                                        value={person.phoneNumber}
                                        name="phoneNumber"
                                        type="text"
                                        className="form-control"
                                        placeholder="Telefono..."
                                      />
                                    </td>
                                    <td>
                                      <label>
                                        <small className="text-primary">
                                          Centro de costo{" "}
                                          <span className="text-danger">*</span>
                                        </small>
                                      </label>
                                      <AsyncSelect
                                        onFocus={() => {
                                          getCostCenters();
                                        }}
                                        defaultOptions={mapValues(costCenters)}
                                        value={person.costCenter}
                                        isLoading={costCentersLoading}
                                        loadOptions={(e) =>
                                          handleLoadSelectOptions(
                                            e,
                                            getCostCenters
                                          )
                                        }
                                        placeholder="buscar..."
                                        onChange={(e) =>
                                          handleArrayChange(
                                            {
                                              target: {
                                                value: e,
                                                name: "costCenter",
                                              },
                                            },
                                            i,
                                            "taxiPersons"
                                          )
                                        }
                                      />
                                    </td>
                                    <td>
                                      <label>
                                        <small className="text-primary">
                                          Motivo
                                        </small>
                                      </label>
                                      <input
                                        onChange={(e) =>
                                          handleArrayChange(e, i, "taxiPersons")
                                        }
                                        value={person.subject}
                                        name="subject"
                                        type="text"
                                        className="form-control"
                                        placeholder="motivo..."
                                      />
                                    </td>
                                    <td>
                                      <label>
                                        <small className="text-primary">
                                          Cobro por Formular{" "}
                                          <span className="text-danger">*</span>
                                        </small>
                                      </label>
                                      <select className="form-control"
                                        value={person?.chargeForFormulating}
                                        name="chargeForFormulating"
                                        onChange={(e) =>
                                          handleArrayChange(e, i, "taxiPersons")
                                        }
                                      >
                                        <option value="no">No</option>
                                        <option value="si">Si</option>
                                      </select>
                                    </td>
                                    <td>
                                      <div className="d-flex align-items-center">
                                        <button
                                          onClick={() =>
                                            removeFromArray(i, "taxiPersons")
                                          }
                                          title="Remover Persona"
                                          className="btn btn-xs btn-danger"
                                        >
                                          X
                                        </button>
                                      </div>
                                    </td>
                                  </tr>
                                );
                              })}
                              <tr>
                                <td colSpan={7}>
                                  <div className="text-center">
                                    <button
                                      onClick={() => {
                                        setData((oldData) => {
                                          return {
                                            ...oldData,
                                            taxiPersons: [],
                                          };
                                        });
                                      }}
                                      className="btn btn-xs btn-danger"
                                    >
                                      Remover Todas
                                    </button>
                                    <button
                                      onClick={() => {
                                        setData((oldData) => {
                                          return {
                                            ...oldData,
                                            taxiPersons: [
                                              ...oldData?.taxiPersons,
                                              {
                                                name: "",
                                                rut: "",
                                                email: "",
                                                phoneNumber: "",
                                                costCenter: "",
                                                subject: "",
                                                chargeForFormulating: 'no'
                                              },
                                            ],
                                          };
                                        });
                                      }}
                                      className="btn btn-xs btn-primary"
                                    >
                                      Agregar Persona
                                    </button>
                                  </div>
                                </td>
                              </tr>
                            </tbody>
                          </table>
                        </div>
                      </div>
                      <div className="col-md-12 mt-3">
                        <label>Observaciones.</label>
                        <textarea
                          name="taxiObservations"
                          style={{ minHeight: "150px" }}
                          value={data.taxiObservations}
                          className="form-control"
                          onChange={handleChange}
                          placeholder="Observaciones"
                        />
                      </div>
                    </div>
                  </div>
                )}
              </div>
            </div>
          </div>
        </div>
        <div className="card-footer text-end">
          <button
            type="button"
            className="btn btn-danger mx-1"
            onClick={() =>
              setCurrentStep((oldStep) => {
                return oldStep - 1;
              })
            }
          >
            Atras
          </button>
          <button
            type="button"
            className="btn btn-primary mx-1"
            disabled={!canContinue}
            onClick={() => {
              if (data?.taxi === 'si') {
                setShowAlert(true);
              } else {
                setCurrentStep((oldStep) => oldStep + 1);
              }
            }}
          >
            Siguiente
          </button>
        </div>
      </div>
      <Modal show={showAlert} onHide={() => setShowAlert(false)} centered>
        <Modal.Header closeButton>
          <Modal.Title className="w-100">
            <div className="w-100 text-center">
              ¡HOLA!
            </div>
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          Por favor verificar que el número telefónico esté correcto, de lo contrario editar manualmente.
          <br />
          <br />
          <div className="text-center">
            <h4>Gracias...</h4>
            <br />
            <button
              onClick={() => setShowAlert(false)}
              className="btn btn-danger me-1"
            >
              Quiero volver y editarlo
            </button>
            <button
              disabled={!canContinue}
              onClick={() => {
                setCurrentStep((oldStep) => oldStep + 1);
              }}
              className="btn btn-primary"
            >
              Si, ya lo he verificado
            </button>
          </div>
        </Modal.Body>
      </Modal>
    </div >
  );
};

export default StepFour;
