import { useEffect, useState } from "react";
import { useSearchParams } from "react-router-dom";
import CustomTable from "../../../components/CustomTable/CustomTable";
import { useFeedBack } from "../../../context/FeedBackContext";
import useExceptions from "../../../hooks/useExceptions";
import ExceptionColumns from "../../../components/CustomTable/Columns/ExceptionColumns";
import TravelExceptionsGlobalActions from "../../../components/TravelExceptionsGlobalActions";

const Exceptions = () => {

    let [searchParams, setSearchParams] = useSearchParams();

    const { setCustomAlert, setLoading } = useFeedBack();

    const [filters, setFilters] = useState({
        page: 1,
        name: '',
        email: '',
        document_number: '',
        withException: ''
    });

    const [selectedValues, setSelectedValues] = useState([]);

    const [selectAll, setSelectAll] = useState(false);

    const [{ exceptions: records, total, numberOfPages, loading }, getRecords] = useExceptions({ params: filters, options: { useCache: false } });

    useEffect(() => {
        var defaultFilters = { ...filters };
        Object.keys(filters).map((keyName) => {
            if (searchParams.get(keyName)) {
                defaultFilters[keyName] = searchParams.get(keyName) ?? '';
            }
        });
        setFilters(defaultFilters);
    }, [])

    useEffect(() => {
        getRecords({
            params: filters
        });

        setSearchParams(filters);
    }, [filters])

    useEffect(() => {
        if (selectAll) {
            setSelectedValues(records?.map?.((value) => value?.id))
        } else {
            setSelectedValues([])
        }
    }, [selectAll])

    const handleSelectALL = () => {
        setSelectAll((oldSelectAll) => !oldSelectAll);
    }

    const handleSelectValue = (selectedValue) => {
        const value = selectedValues?.includes(Number(selectedValue?.id));
        if (value) {
            const newValues = selectedValues?.filter(n => n !== Number(selectedValue?.id));
            setSelectedValues(newValues);
        } else {
            setSelectedValues((oldSelectedValues) => [...oldSelectedValues, Number(selectedValue?.id)])
        }
    }

    const handlePageChange = (page) => {
        if (page <= numberOfPages && page > 0) {
            setFilters((oldFilters) => {
                return {
                    ...oldFilters,
                    page: page
                }
            })
        }
    }

    const handleChange = (e) => {
        setFilters((oldFilters) => {
            return {
                ...oldFilters,
                [e.target.name]: e.target.value,
                page: 1
            }
        })
    }

    const handleUpdateException = () => {
        getRecords();
    }

    return (
        <div>
            <div className="row">
                <div className="col-md-6">
                    <div className="card p-4">
                        <div className="form-group">
                            <label className="text-primary">Nombre del trabajador</label>
                            <input
                                type="text"
                                className="form-control"
                                value={filters?.name}
                                onChange={handleChange}
                                name="name"
                                placeholder="name"
                            />
                        </div>
                    </div>
                </div>
                <div className="col-md-6">
                    <div className="card p-4">
                        <div className="form-group">
                            <label className="text-primary">Email del trabajador</label>
                            <input
                                type="text"
                                className="form-control"
                                value={filters?.email}
                                onChange={handleChange}
                                name="email"
                                placeholder="email"
                            />
                        </div>
                    </div>
                </div>
                <div className="col-md-6">
                    <div className="card p-4">
                        <div className="form-group">
                            <label className="text-primary">RUT</label>
                            <input
                                type="text"
                                className="form-control"
                                value={filters?.document_number}
                                onChange={handleChange}
                                name="document_number"
                                placeholder="Rut del trabajador"
                            />
                        </div>
                    </div>
                </div>
                <div className="col-md-6">
                    <div className="card p-4">
                        <div className="form-group">
                            <label className="text-primary">Ver solo:</label>
                            <select name="withException" className="form-control" value={filters?.withException} onChange={handleChange}>
                                <option value="">Todos</option>
                                <option value="si">Con excepción</option>
                                <option value="no">Sin excepción</option>
                            </select>
                        </div>
                    </div>
                </div>
            </div>

            <CustomTable
                CustomGlobalActionComponent={<TravelExceptionsGlobalActions selectedValues={selectedValues} onUpdateException={handleUpdateException} />}
                onSelectValue={handleSelectValue}
                onSelectAll={handleSelectALL}
                loading={loading}
                selectAll={selectAll}
                title={'Trabajadores'}
                selectedValues={selectedValues}
                pages={numberOfPages}
                total={total}
                values={records}
                currentPage={filters?.page}
                collumns={ExceptionColumns}
                changePage={handlePageChange}
                onPerPageChange={handleChange}
                perPage={filters?.perPage}
            />
        </div>
    )
}

export default Exceptions;