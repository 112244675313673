import { useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { useFeedBack } from "../../../../context/FeedBackContext";
import { useTravelCrud } from "../../../../context/TravelCrudContext";
import useAxios from "../../../../hooks/useAxios";

const StepFive = () => {
  const { setLoading, setCustomAlert } = useFeedBack();

  const navigate = useNavigate();

  const { data, setData, currentStep, setCurrentStep } = useTravelCrud();

  const [
    { data: createData, loading: createLoading, error: createError },
    createRecord,
  ] = useAxios(
    { url: `/travels`, method: "POST" },
    { manual: true, useCache: false }
  );

  useEffect(() => {
    setLoading({
      loading: createLoading,
      message: "Creando solicitud",
    });
  }, [createLoading]);

  useEffect(() => {
    if (createData) {
      setCustomAlert({
        show: true,
        message: "El registro ha sido creado exitosamente.",
        severity: "success",
        title: "Operación Exitosa",
      });
      navigate("/mi-cuenta/mis-viajes");
    }
  }, [createData]);

  const handleChange = (e) => {
    setData((oldData) => {
      return {
        ...oldData,
        [e.target.name]: e.target.value,
      };
    });
  };
  const handleSubmit = (e) => {
    e?.preventDefault();

    if (
      data.taxi === "no" &&
      data.accommodation === "no" &&
      data.travel === "no"
    ) {
      alert("Necesita solicitar al menos un servicio.");
      return;
    }

    var dataToSend = {
      reason: data?.reason,
      travel: data?.travel,
      accommodation: data?.accommodation,
      taxi: data?.taxi,
    };

    if (data?.travel === "si") {

      dataToSend.travelBaggage = data?.travelBaggage;
      dataToSend.travelModality = data?.travelModality;
      dataToSend.travelStart = data?.travelStart;
      dataToSend.travelStartHour = data?.travelStartHour;
      dataToSend.travelStartFromCommune = data?.travelStartFromCommune?.value;
      dataToSend.travelStartToCommune = data?.travelStartToCommune?.value;
      dataToSend.travelBack = data?.travelBack;
      dataToSend.travelEnd = data?.travelEnd;
      dataToSend.travelObservations = data?.travelObservations;
      dataToSend.travelEndHour = data?.travelEndHour;
      dataToSend.travelEndFromCommune = data?.travelEndFromCommune?.value;
      dataToSend.travelEndToCommune = data?.travelEndToCommune?.value;
      dataToSend.travelPersons = [
        ...data?.travelPersons.map((person) => {
          return {
            ...person,
            costCenter: person?.costCenter?.value,
          };
        }),
      ];
    }

    if (data?.accommodation === "si") {

      dataToSend.accommodationStart = data?.accommodationStart;
      dataToSend.accommodationEnd = data?.accommodationEnd;
      dataToSend.accommodationCommune = data?.accommodationCommune?.value;
      dataToSend.accommodationPersons = [
        ...data?.accommodationPersons?.map((person) => {
          return {
            ...person,
            costCenter: person?.costCenter?.value,
          };
        })
      ];
      dataToSend.accommodationObservations = data?.accommodationObservations;
    }

    if (data?.taxi === "si") {

      dataToSend.taxiStart = data?.taxiStart;
      dataToSend.taxiStartHour = data?.taxiStartHour;
      dataToSend.taxiStartCommune = data?.taxiStartCommune?.value;
      dataToSend.taxiStartFrom = data?.taxiStartFrom;
      dataToSend.taxiStartFromPoint = data?.taxiStartFromPoint;
      dataToSend.taxiStartTo = data?.taxiStartTo;
      dataToSend.taxiStartToPoint = data?.taxiStartToPoint;
      dataToSend.taxiBack = data?.taxiBack;
      dataToSend.taxiEnd = data?.taxiEnd;
      dataToSend.taxiEndHour = data?.taxiEndHour;
      dataToSend.taxiEndCommune = data?.taxiEndCommune?.value;
      dataToSend.taxiEndFrom = data?.taxiEndFrom;
      dataToSend.taxiEndFromPoint = data?.taxiEndFromPoint;
      dataToSend.taxiEndTo = data?.taxiEndTo;
      dataToSend.taxiEndToPoint = data?.taxiEndToPoint;
      dataToSend.taxiObservations = data?.taxiObservations;
      dataToSend.taxiPersons = [
        ...data?.taxiPersons?.map((person) => {
          return {
            ...person,
            costCenter: person?.costCenter?.value,
          };
        })
      ];
    }

    createRecord({ data: dataToSend });
  };

  return (
    <div className="container">
      <form onSubmit={handleSubmit}>
        <div className="card col-md-12 animate__animated animate__fadeInUp p-4">
          <div className="card-header">
            <h4>Motivo por el cual hace la solicitud</h4>
          </div>
          <div className="card-body">
            <div className="row">
              <div className="form-group mb-3 col-md-12">
                <h3>Motivo de Solicitud</h3>
                <textarea
                  name="reason"
                  style={{ minHeight: "150px" }}
                  value={data.reason}
                  className="form-control"
                  onChange={handleChange}
                  placeholder="Ej: Reunión de negocio"
                />
              </div>
            </div>
          </div>
          <div className="card-footer text-end">
            <button
              type="button"
              className="btn btn-danger mx-1"
              onClick={() =>
                setCurrentStep((oldStep) => {
                  return oldStep - 1;
                })
              }
            >
              Atras
            </button>
            <button
              disabled={!data?.reason || createLoading}
              className="btn btn-primary mx-1"
            >
              Enviar
            </button>
          </div>
        </div>
      </form>
    </div>
  );
};

export default StepFive;
