export const statuses = [
    {
        id: 1,
        name: 'Pendiente',
        pluralName: 'Pendientes',
        color: '#f0e51d',
        variantColor: "warning"
    },
    {
        id: 5,
        name: 'Rechazado',
        pluralName: 'Rechazados',
        color: '#ed1a36',
        variantColor: "danger"
    },
    {
        id: 3,
        name: 'Procesando',
        pluralName: 'Procesando',
        color: '#1362FC',
        variantColor: "primary"
    },
    {
        id: 4,
        name: 'Finalizado',
        pluralName: 'Finalizados',
        color: '#00D487',
        variantColor: "success"
    },
    {
        id: 2,
        name: 'Anulado',
        pluralName: 'Anulados',
        color: '#FF0000',
        variantColor: "danger"
    },
];

export const weekDaysNames = [
    {
        name: 'Domingo'
    },
    {
        name: 'Lunes'
    },
    {
        name: 'Martes'
    },
    {
        name: 'Miércoles'
    },

    {
        name: 'Jueves'
    },

    {
        name: 'Viernes'
    },

    {
        name: 'Sábado'
    }
];

export const monthNames = [
    {
        name: 'Enero'
    },
    {
        name: 'Febrero'
    },
    {
        name: 'Marzo'
    },
    {
        name: 'Abril'
    },

    {
        name: 'Mayo'
    },

    {
        name: 'Junio'
    },

    {
        name: 'Julio'
    },

    {
        name: 'Agosto'
    },

    {
        name: 'Septiembre'
    },

    {
        name: 'Octubre'
    },

    {
        name: 'Noviembre'
    },

    {
        name: 'Diciembre'
    }
];