import { useEffect, useState } from 'react';
import useAxios from './useAxios';

const useNotificationEmails = ({ options, axiosConfig } = {}) => {
  const [{ data, error, loading }, getNotificationEmails] = useAxios({ url: '/notification-emails', ...axiosConfig }, options);

  const [notificationEmails, setNotificationEmails] = useState([]);

  const [total, setTotal] = useState(0);

  const [size, setSize] = useState(0);

  const [numberOfPages, setNumberOfPages] = useState(0);

  useEffect(() => {
    if (data) {
      setNotificationEmails(data.data);
      setTotal(data?.meta?.total);
      setSize(data?.meta?.per_page);
      setNumberOfPages(data.meta?.last_page);
    }

  }, [data, loading, error]);

  return [{ notificationEmails, total, numberOfPages, size, error, loading }, getNotificationEmails];
};

export default useNotificationEmails;
