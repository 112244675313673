import { Dropdown } from "react-bootstrap";
import useStatuses from "../hooks/useStatuses";
import { useEffect, useState } from "react";
import useAxios from "../hooks/useAxios";
import swal from "sweetalert";

const TravelDetailStatusComponent = ({ travel }) => {

    const [currentStatus, setCurrentStatus] = useState(null);

    const [availableStatusIds, setAvailableStatusIds] = useState([]);

    const [{ statuses }] = useStatuses({ axiosConfig: { params: { ids: availableStatusIds } }, options: { useCache: false } });

    const [{ data, loading }, updateStatus] = useAxios({ url: `/travels/${travel?.id}/status`, method: 'PUT' }, { manual: true, useCache: false });

    useEffect(() => {
        if (data) {
            setCurrentStatus(data?.data?.status);
            setAvailableStatusIds(data?.data?.availableStatuses);
        }
    }, [data]);

    useEffect(() => {
        if (travel) {
            setCurrentStatus(travel?.status);
            setAvailableStatusIds(travel?.availableStatuses);
        };
    }, [travel]);

    const handleStatus = (status) => {
        swal({
            title: "¿Estas seguro/a?",
            text: "¿Quieres modificar el estatus de la solicitud?",
            icon: "warning",
            buttons: true,
            dangerMode: true,
        }).then((willUpdate) => {
            if (willUpdate) updateStatus({ data: { statusId: status?.id } });
        })
    }

    return (
        <Dropdown>
            <Dropdown.Toggle disabled={loading || availableStatusIds?.length === 0} variant={currentStatus?.variantColor} id="dropdown-basic">
                {
                    loading ?
                        'Cargando...'
                        :
                        currentStatus?.name
                }
            </Dropdown.Toggle>

            {
                availableStatusIds?.length > 0 &&
                <Dropdown.Menu>
                    {
                        statuses?.map((status, i) => {
                            return (
                                <Dropdown.Item as="button" onClick={() => handleStatus(status)} style={{ color: status?.color }}>
                                    {status?.name}
                                </Dropdown.Item>
                            )
                        })
                    }
                </Dropdown.Menu>
            }
        </Dropdown>
    )
}

export default TravelDetailStatusComponent;