import imgUrl from "../util/imgUrl";

const ImageAndName = ({ nameValue, imgValue, value }) => {
    return (
        <div style={{ justifyContent: 'left', display: 'flex', alignItems: 'center', textAlign: 'center' }}>
            <img
                src={imgValue}
                style={{ borderRadius: '100%' }}
                width="36"
                height="36"
                alt="profile-image"
            />
            <span style={{ margin: '0px 10px', textAlign: 'left' }}>{nameValue ? nameValue : ''}</span>
        </div>
    )
}

export default ImageAndName;