import ActionDropdown from "../../ActionDropdown";
import TableCheck from "../TableCheck";
import { format } from "date-fns";
import RenderStatus from "../../RenderStatus";
import DateFormatter from "../../DateFormatter";
import ImageAndName from "../../ImageAndName";

const NotificationEmailsColumns = [
    {
        Label: TableCheck,
        Component: TableCheck

    },
    {
        Label: () => 'id',
        accessor: 'id'
    },
    {
        Label: () => 'Email',
        Component: ({ value }) => `${value?.email || "--"}`
    },
    {
        Label: () => 'Para Notificar',
        Component: ({ value }) => <div>
            {value?.for === 'travel' && 'Viajes'}
            {value?.for === 'accommodation' && 'Hospedajes'}
            {value?.for === 'taxi' && 'Taxis'}
        </div>
    },
    {
        Label: () => 'Fecha de Creación',
        Component: ({ value }) => <DateFormatter value={value?.createdAt} dateFormat='d/MM/Y' />
    },
    {
        Label: () => 'Acciones',
        Component: ActionDropdown
    }
];

export default NotificationEmailsColumns;

