import { useEffect, useState } from "react";
import { Button, Modal, Table } from "react-bootstrap";
import dateOnlyConverter from "../util/dateOnlyConverter";
import DateFormatter from "./DateFormatter";
import Map from "./googlemaps/Map";
import { useFeedBack } from "../context/FeedBackContext";
import useAxios from "../hooks/useAxios";
import { MdEdit } from "react-icons/md";
import AutoCompleteInput from "./googlemaps/AutoCompleteInput";
import { IoMdClose } from "react-icons/io";


const TaxiSummary = ({ travel, onExport, loadingExport, canEdit, onUpdate }) => {
  const [showPersonsModal, setShowPersonsModal] = useState(false);

  const [polygonPath, setPolygonPath] = useState(null);

  const [valuesToEdit, setValuesToEdit] = useState({
    title: '',
    date: '',
    hour: '',
    taxiFrom: '',
    taxiFromPoint: '',
    taxiTo: '',
    taxiToPoint: '',
    instance: ''
  });

  const [showModal, setShowModal] = useState(false);

  const { setCustomAlert } = useFeedBack();

  const [{ data, loading }, updateValues] = useAxios({ url: `/travels/${travel?.id}/taxi`, method: 'PUT' }, { manual: true, useCache: false });


  useEffect(() => {
    if (data) {
      setCustomAlert({
        show: true,
        message: "El registro ha sido actualizado exitosamente.",
        severity: "success",
        title: "Operación Exitosa",
      });
      onUpdate?.(data?.data);
      setShowModal(false);
    }
  }, [data])

  if (travel?.taxi !== "si") return null;


  const handleClose = () => {
    setShowPersonsModal((oldValue) => !oldValue);
  };

  const handleEdit = (valueToEdit) => {

    if (valueToEdit === 'start') setValuesToEdit({
      title: 'Editar datos del taxi de ida',
      date: DateFormatter({
        value: dateOnlyConverter(travel?.taxiStart),
        dateFormat: "yyyy-MM-dd",
      }),
      hour: travel?.taxiStartHour,
      taxiFrom: travel?.taxiStartFrom,
      taxiFromPoint: travel?.taxiStartFromPoint,
      taxiTo: travel?.taxiStartTo,
      taxiToPoint: travel?.taxiStartToPoint,
      instance: 'start'
    });

    if (valueToEdit === 'end') setValuesToEdit({
      title: 'Editar datos del taxi de regreso',
      date: DateFormatter({
        value: dateOnlyConverter(travel?.taxiEnd),
        dateFormat: "yyyy-MM-dd",
      }),
      hour: travel?.taxiEndHour,
      taxiFrom: travel?.taxiEndFrom,
      taxiFromPoint: travel?.taxiEndFromPoint,
      taxiTo: travel?.taxiEndTo,
      taxiToPoint: travel?.taxiEndToPoint,
      instance: 'end'
    });

    setShowModal(true);

    setTimeout(() => {
      window.scrollTo(0, document.body.scrollHeight);
    }, 100);
  }

  const handleChange = (e) => {
    if (e.target.latLng) {
      setValuesToEdit((oldData) => {
        return {
          ...oldData,
          [`${e.target.name}Point`]: e.target.latLng,
        };
      });
    }

    setValuesToEdit((oldValues) => {
      return {
        ...oldValues,
        [e.target.name]: e.target.value
      }
    });
  }

  const handleSubmit = (e) => {
    e?.preventDefault?.();

    const { title, ...rest } = valuesToEdit;

    updateValues({
      data: {
        ...rest,
        comuneFrom: rest?.comuneFrom?.value,
        comuneTo: rest?.comuneTo?.value
      }
    });
  }

  return (
    <>
      <div
        className="card"
        style={{ borderTopLeftRadius: 0, borderTopRightRadius: 0 }}
      >
        <div className="card-header">
          <div className="d-flex align-items-center justify-content-between w-100">
            <h5>🚖 Datos del servicio de taxis</h5>
            <button
              className="btn btn-xs btn-success"
              onClick={() => onExport?.("taxi", "taxi")}
            >
              {loadingExport ? "cargando..." : "Exportar a excel"}
            </button>
          </div>
        </div>
        <div className="card-body">
          <div className="row">
            <div className="col-md-6 mb-3">
              <label className="text-primary">Usuario Solicitante</label>
              <input
                readOnly
                type="text"
                value={travel?.user?.name || "--"}
                className="form-control"
              />
            </div>
            <div className="col-md-6 mb-3">
              <label className="text-primary d-flex justify-content-between align-items-center">
                Número de personas
                <span
                  type="button"
                  className="text-primary"
                  onClick={handleClose}
                >
                  Ver Detalles
                </span>
              </label>
              <input
                readOnly
                type="text"
                value={travel?.taxiPersons?.length}
                className="form-control"
              />
            </div>
            <div className="col-md-12 mb-3 mt-4">
              <div className="d-flex align-items-center justify-content-between">
                <h3>Datos del servicio taxi de ida</h3>
                {
                  canEdit &&
                  <button onClick={() => handleEdit('start')} className="btn btn-xs btn-primary">
                    Editar  <MdEdit />
                  </button>
                }
              </div>
            </div>
            <div className="col-md-6 mb-3">
              <div className="row align-items-center">
                <div className="col-md-6 mb-3">
                  <label className="text-primary">Fecha de taxi ida:</label>
                  <input
                    readOnly
                    type="text"
                    value={DateFormatter({
                      value: dateOnlyConverter(travel?.taxiStart),
                      dateFormat: "d/MM/yyyy",
                    })}
                    className="form-control"
                  />
                </div>

                <div className="col-md-6 mb-3">
                  <label className="text-primary">Hora:</label>
                  <input
                    readOnly
                    type="text"
                    value={travel?.taxiStartHour}
                    className="form-control"
                  />
                </div>

                <div className="col-md-12 mb-3">
                  <label className="text-primary">
                    Desde:
                  </label>
                  <input
                    readOnly
                    type="text"
                    value={travel?.taxiStartFrom || "--"}
                    className="form-control"
                  />
                </div>
                <div className="col-md-12 mb-3">
                  <label className="text-primary">
                    Hasta:
                  </label>
                  <input
                    readOnly
                    type="text"
                    value={travel?.taxiStartTo || "--"}
                    className="form-control"
                  />
                </div>
              </div>
            </div>
            <div className="col-md-6 mb-3">
              {
                travel?.taxiStartFromPoint && travel?.taxiStartToPoint ?
                  <Map
                    options={{
                      center: travel?.taxiStartFromPoint,
                      zoom: 5
                    }}
                    markers={[travel?.taxiStartFromPoint, travel?.taxiStartToPoint]}
                    defaultPolygon={[travel?.taxiStartFromPoint, travel?.taxiStartToPoint]}
                  />
                  :
                  null
              }
            </div>
            <div className="col-md-12 mb-3 mt-4">
              <div className="d-flex align-items-center justify-content-between">
                <h3>Datos del servicio taxi de regreso</h3>
                {
                  canEdit && travel?.taxiBack ?
                    <button onClick={() => handleEdit('end')} className="btn btn-xs btn-primary">
                      Editar  <MdEdit />
                    </button>
                    :
                    null
                }
              </div>
            </div>
            {travel?.taxiBack ? (
              <>
                <div className="col-md-6 mb-3">
                  <div className="row align-items-center">
                    <div className="col-md-6 mb-3">
                      <label className="text-primary">Fecha de taxi regreso</label>
                      <input
                        readOnly
                        type="text"
                        value={DateFormatter({
                          value: dateOnlyConverter(travel?.taxiEnd),
                          dateFormat: "d/MM/yyyy",
                        })}
                        className="form-control"
                      />
                    </div>
                    <div className="col-md-6 mb-3">
                      <label className="text-primary">Hora:</label>
                      <input
                        readOnly
                        type="text"
                        value={travel?.taxiEndHour}
                        className="form-control"
                      />
                    </div>
                    <div className="col-md-12 mb-3">
                      <label className="text-primary">
                        Desde:
                      </label>
                      <input
                        readOnly
                        type="text"
                        value={travel?.taxiEndFrom || "--"}
                        className="form-control"
                      />
                    </div>
                    <div className="col-md-12 mb-3">
                      <label className="text-primary">
                        Hasta:
                      </label>
                      <input
                        readOnly
                        type="text"
                        value={travel?.taxiEndTo || "--"}
                        className="form-control"
                      />
                    </div>
                  </div>
                </div>
                <div className="col-md-6 mb-3">
                  {
                    travel?.taxiEndFromPoint && travel?.taxiEndToPoint ?
                      <Map
                        options={{
                          center: travel?.taxiEndFromPoint,
                          zoom: 5
                        }}
                        markers={[travel?.taxiEndFromPoint, travel?.taxiEndToPoint]}
                        defaultPolygon={[travel?.taxiEndFromPoint, travel?.taxiEndToPoint]}
                      />
                      :
                      null
                  }
                </div>
              </>
            ) : (
              <div className="col-md-12">
                <p className="text-danger">
                  No necesitan taxis para un viaje de vuelta.
                </p>
              </div>
            )}
            <div className="col-md-12 my-3">
              <label className="text-primary">Observaciones:</label>
              <textarea
                rows={4}
                style={{ minHeight: 150 }}
                readOnly
                type="text"
                value={travel?.taxiObservations || "--"}
                className="form-control"
              />
            </div>
          </div>
        </div>
      </div>
      {
        showModal &&
        <div className="card p-4 mt-5" id="form-edit">
          <div className="d-flex align-items-center justify-content-between mb-5">
            <h3>{valuesToEdit?.title}</h3>
            <button className="btn btn-xs btn-danger" onClick={() => setShowModal(false)}>
              <IoMdClose />
            </button>
          </div>

          <form onSubmit={handleSubmit}>
            <div className="row">
              <div className="col-md-6 mb-3">
                <label className="text-primary">
                  Fecha
                  <small className="text-danger">*</small>
                </label>
                <input
                  type="date"
                  value={valuesToEdit?.date}
                  className="form-control"
                  name="date"
                  onChange={handleChange}
                />
              </div>
              <div className="col-md-6 mb-3">
                <label className="text-primary">
                  Hora
                  <small className="text-danger">*</small>
                </label>
                <input
                  type="time"
                  name="hour"
                  onChange={handleChange}
                  value={valuesToEdit.hour}
                  required
                  className="form-control"
                />
              </div>
              <div className="col-md-6 mb-3">
                <label className="text-primary">
                  Desde
                  <small className="text-danger">*</small>
                </label>
                <AutoCompleteInput
                  className="form-control"
                  name="taxiFrom"
                  value={valuesToEdit?.taxiFrom}
                  onChange={handleChange}
                  placeholder="Ej. Calle grande #7651.."
                />
              </div>
              <div className="col-md-6 mb-3">
                <label className="text-primary">
                  Hasta
                  <small className="text-danger">*</small>
                </label>
                <AutoCompleteInput
                  className="form-control"
                  name="taxiTo"
                  value={valuesToEdit?.taxiTo}
                  onChange={handleChange}
                  placeholder="Ej. Calle grande #7651.."
                />
              </div>
              <div className="col-md-12 mb-3">
                <Map
                  options={{
                    center: { lat: -33.445245, lng: -70.660581 },
                    zoom: 5
                  }}
                  markers={[valuesToEdit?.taxiFromPoint, valuesToEdit?.taxiToPoint]}
                  defaultPolygon={[valuesToEdit?.taxiFromPoint, valuesToEdit?.taxiToPoint]}
                />
              </div>
            </div>
            <div className="row align-items-center w-100">
              <div className="col-sm-6 mb-3 mb-sm-0">
                <button className="btn btn-danger btn-block" type="button" variant="danger" onClick={() => setShowModal(false)}>
                  Cancelar
                </button>
              </div>
              <div className="col-sm-6 mb-3 mb-sm-0">
                <button disabled={loading} className="btn btn-primary btn-block" variant="primary" >
                  {
                    loading ?
                      <div className="spinner" style={{ width: 21, height: 21 }}>
                        <div className="double-bounce1 bg-light"></div>
                        <div className="double-bounce2 bg-light"></div>
                      </div>
                      :
                      'Enviar'
                  }
                </button>
              </div>
            </div>
          </form>
        </div>
      }


      <Modal show={showPersonsModal} onHide={handleClose} size="xl">
        <Modal.Header closeButton>
          <Modal.Title>Detalle de las personas</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <div className="table-responsive">
            <Table striped>
              <thead>
                <tr>
                  <th style={{ fontSize: 12 }}>#</th>
                  <th style={{ fontSize: 12 }}>Nombre</th>
                  <th style={{ fontSize: 12 }}>Rut</th>
                  <th style={{ fontSize: 12 }}>Teléfono</th>
                  <th style={{ fontSize: 12 }}>Email</th>
                  <th style={{ fontSize: 12 }}>Centro de costo</th>
                  <th style={{ fontSize: 12 }}>Cobro por formular</th>
                  <th style={{ fontSize: 12 }}>Motivo</th>
                </tr>
              </thead>
              <tbody>
                {travel?.taxiPersons?.map((person, i) => {
                  return (
                    <tr key={i}>
                      <td>{i + 1}</td>
                      <td>{person?.name || "--"}</td>
                      <td>{person?.rut || "--"}</td>
                      <td>{person?.phoneNumber || "--"}</td>
                      <td>{person?.email || "--"}</td>
                      <td>{person?.costCenter?.name || "--"}</td>
                      <td style={{ textTransform: 'capitalize' }}>{person?.chargeForFormulating || "--"}</td>
                      <td>{person?.subject || "--"}</td>
                    </tr>
                  );
                })}
              </tbody>
            </Table>
          </div>
        </Modal.Body>
        <Modal.Footer className="text-center justify-content-center">
          <Button variant="danger" onClick={handleClose}>
            Cerrar
          </Button>
        </Modal.Footer>
      </Modal>
    </>
  );
};

export default TaxiSummary;
