import { useEffect, useState } from "react"
import { Modal } from "react-bootstrap";
import useAxios from "../../hooks/useAxios";
import { useFeedBack } from "../../context/FeedBackContext";
import FavoriteCard from "./FavoriteCard";

const ShowFavoritesModal = ({ type, show, onHide, handleFavorite }) => {

    const { setCustomAlert } = useFeedBack();

    const [filters, setFilters] = useState({
        search: '',
        type,
        page: 1
    });

    const [currentFavorites, setCurrentFavorites] = useState([]);

    const [{ data: response, loading }, getFavorites] = useAxios({ url: `/my-account/favorites`, params: filters }, { useCache: false });

    useEffect(() => {
        if (show) {
            setCurrentFavorites([]);
            setFilters({
                search: '',
                type,
                page: 1
            });
            getFavorites?.();
        }
    }, [show])

    useEffect(() => {
        if (response) {
            setCurrentFavorites((oldFavorites) => {
                return [...oldFavorites, ...response?.data];
            });
        }
    }, [response]);

    const handleChange = (e) => {
        setCurrentFavorites([]);
        setFilters((oldFilters) => {
            return {
                ...oldFilters,
                [e.target.name]: e.target.value,
                page: 1
            }
        });
    }

    const handleFavoriteDelete = (favoriteDeleted) => {

        const newFavorites = currentFavorites.filter((favorite, i) => favorite?.id !== favoriteDeleted?.id);

        setCurrentFavorites(newFavorites);
    }

    return (
        <Modal show={show} onHide={() => onHide?.()}>
            <Modal.Header closeButton>
                <Modal.Title>Tus Favoritos</Modal.Title>
            </Modal.Header>
            <Modal.Body>
                <input
                    type="text"
                    className="form-control"
                    value={filters?.name}
                    onChange={handleChange}
                    placeholder="Buscar"
                    name="search"
                />
                <br />
                <ul className="custom-scrollbar scrollbar-primary" style={{ maxHeight: '70vh', overflowY: 'auto' }}>
                    {
                        !loading && currentFavorites?.length === 0 ?
                            <li className="text-center">
                                No se encontrarón resultados.
                            </li>
                            :
                            null
                    }
                    {

                        currentFavorites?.map((favorite, i) => {
                            return (
                                <FavoriteCard
                                    favorite={favorite}
                                    onDelete={handleFavoriteDelete}
                                    key={i}
                                    onHandleFavorite={handleFavorite}
                                />
                            )
                        })
                    }
                    {
                        loading &&
                        <li className="text-center">
                            <div className="spinner">
                                <div className="double-bounce1 bg-primary"></div>
                                <div className="double-bounce2 bg-primary"></div>
                            </div>
                        </li>
                    }
                    {
                        response?.meta.last_page > filters?.page && !loading ?
                            <li className="text-center">
                                <button className="btn btn-primary btn-xs" onClick={() => {
                                    setFilters((oldFilters) => {
                                        return {
                                            ...oldFilters,
                                            page: oldFilters?.page + 1
                                        }
                                    })
                                }}>
                                    Cargar más
                                </button>
                            </li>
                            :
                            null
                    }
                </ul>
            </Modal.Body>
        </Modal>
    )
}

export default ShowFavoritesModal;