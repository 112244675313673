import { useState, useEffect } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { useFeedBack } from "../../../context/FeedBackContext";
import useAxios from "../../../hooks/useAxios";
import TravelSummary from "../../../components/TravelSummary";
import AccommodationSummary from "../../../components/AccommodationSummary";
import TaxiSummary from "../../../components/TaxiSummary";
import { Tab, Tabs } from "react-bootstrap";
import TravelDetailStatusComponent from "../../../components/TravelDetailStatusComponent";

const MyTravelsDetail = () => {

    const navigate = useNavigate();

    const { id } = useParams();

    const { setLoading } = useFeedBack();

    const [{ data: record, loading: loadingRecord }, getRecord] = useAxios({ url: `/my-account/travels/${id}` }, { useCache: 'false' });

    const [currentFileName, setCurrentFileName] = useState('');

    const [{ loading: loadingExcel }, getExcelFile] = useAxios({ method: 'GET', responseType: 'blob' }, { useCache: false, manual: true });

    const [currentRecord, setCurrentRecord] = useState(null);

    useEffect(() => {
        setLoading({
            message: 'Cargando',
            loading: loadingRecord
        })
    }, [loadingRecord]);


    useEffect(() => {
        if (record) {
            setCurrentRecord((oldCurrentRecord) => {
                return {
                    ...oldCurrentRecord,
                    ...record?.data
                }
            })
        }
    }, [record])

    const findDefaultKey = () => {
        if (currentRecord?.travel === 'si') {
            return 'travel'
        }

        if (currentRecord?.accommodation === 'si') {
            return 'accommodation'
        }

        if (currentRecord?.taxi === 'si') {
            return 'taxi'
        }
    }

    const handleExport = (exporType, spanishType) => {
        setCurrentFileName(`solicitud_de_${spanishType}_${id}.xlsx`);
        getExcelFile({
            url: `/my-account/travels/${id}/excel`,
            params: {
                export: exporType
            }
        }).then((response) => {
            handleBlobResponse(response?.data);
        });
    }

    const handleBlobResponse = (blobResponse) => {
        const fileBlobUrl = URL.createObjectURL(blobResponse);
        const aToDownload = document.getElementById('downloadLink');
        aToDownload.href = fileBlobUrl;
        aToDownload?.click();
        window.URL.revokeObjectURL(fileBlobUrl);
    }

    return (
        <div>
            <a id="downloadLink" style={{ display: 'none' }} download={currentFileName}></a>
            <div className="d-flex align-items-center justify-content-end">
                <button className="btn btn-primary me-2" onClick={() => navigate(-1)}>
                    Volver atras
                </button>
                <TravelDetailStatusComponent travel={currentRecord} />
            </div>
            <Tabs
                defaultActiveKey={findDefaultKey()}
                className=""
            >
                {
                    currentRecord?.travel === 'si' &&
                    <Tab eventKey="travel" title="✈️ Datos del Viaje">
                        <TravelSummary loadingExport={loadingExcel} travel={currentRecord} onExport={handleExport} />
                    </Tab>
                }
                {
                    currentRecord?.accommodation === 'si' &&
                    <Tab eventKey="accommodation" title="🏨 Datos del Hospedaje">
                        <AccommodationSummary loadingExport={loadingExcel} travel={currentRecord} onExport={handleExport} />
                    </Tab>
                }
                {
                    currentRecord?.taxi === 'si' &&
                    <Tab eventKey="taxi" title="🚖 Datos del taxi">
                        <TaxiSummary loadingExport={loadingExcel} travel={currentRecord} onExport={handleExport} />
                    </Tab>
                }
            </Tabs>
        </div>
    )
}

export default MyTravelsDetail;