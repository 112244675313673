import { useState } from "react"
import { Button, Modal } from "react-bootstrap"
import useAxios from "../hooks/useAxios";
import { useFeedBack } from "../context/FeedBackContext";

const TravelExceptionRequestModal = ({ show, onHide }) => {

    const { setCustomAlert } = useFeedBack();

    const [subject, setSubject] = useState('');

    const [{ loading: loadingRequestException }, requestException] = useAxios({ url: `/travel-exceptions/request`, method: 'POST' }, { manual: true, useCache: false });

    const handleRequestException = () => {
        requestException({
            data: {
                subject
            }
        }).then(() => {
            setCustomAlert({
                show: true,
                message: "La solicitud se ha enviado exitosamente.",
                severity: "success",
                title: "Operación Exitosa",
            });
            onHide?.();
        })
    }

    return (
        <Modal show={show} onHide={onHide}>
            <Modal.Header closeButton>
                <Modal.Title>Motivo</Modal.Title>
            </Modal.Header>
            <Modal.Body>
                <textarea
                    className="form-control"
                    placeholder="Escribe el motivo"
                    value={subject}
                    style={{ height: 150 }}
                    onChange={(e) => setSubject(e.target.value)}
                ></textarea>
            </Modal.Body>
            <Modal.Footer>
                <Button variant="secondary" onClick={onHide}>
                    Cerrar
                </Button>
                <Button disabled={loadingRequestException} variant="primary" onClick={handleRequestException}>
                    {
                        loadingRequestException ?
                            'Enviando...'
                            :
                            'Enviar'
                    }
                </Button>
            </Modal.Footer>
        </Modal>
    )
}

export default TravelExceptionRequestModal;