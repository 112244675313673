import { useEffect, useRef, useState } from "react";
import { Loader } from "@googlemaps/js-api-loader";
import SystemInfo from "../../util/SystemInfo";


const loader = new Loader({
    apiKey: SystemInfo.googleMapApiKey,
    version: "weekly",
    libraries: ["drawing", "geometry", "places", "visualization"],
});

const AutoCompleteInput = ({ onChange, name, value, className, style, ...rest }) => {

    const [mapApi, setMapApi] = useState(null);

    const [autoCompleteInput, setAutoCompleteInput] = useState(null);

    const searchRef = useRef(null);

    useEffect(() => {
        loader.load().then((response) => {
            setMapApi(response);
        });
    }, []);

    useEffect(() => {
        if (mapApi && searchRef.current) {
            setAutoCompleteInput(
                new mapApi.maps.places.Autocomplete(searchRef.current, {
                    types: ["geocode"],
                    componentRestrictions: { country: "cl" },
                    fields: ["geometry", "formatted_address"],
                })
            );
        }
    }, [mapApi, searchRef]);

    useEffect(() => {
        if (autoCompleteInput) {
            autoCompleteInput.addListener("place_changed", () => {
                const { geometry, formatted_address } = autoCompleteInput.getPlace();
                onChange({
                    target: {
                        value: formatted_address,
                        name: name,
                        type: "text",
                        latLng: { lat: geometry.location.lat(), lng: geometry.location.lng() }
                    },
                });
            });
        }
    }, [autoCompleteInput]);

    return (
        <input
            style={style}
            className={className}
            ref={searchRef}
            onChange={onChange}
            value={value}
            name={name}
            type="text"
            {...rest}
        />
    )
}

export default AutoCompleteInput;