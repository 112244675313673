import { useEffect, useState } from "react"
import { Modal } from "react-bootstrap";
import useAxios from "../../hooks/useAxios";
import { useFeedBack } from "../../context/FeedBackContext";

const CreateFavoriteModal = ({ dataToSave, type, show, onHide, namePlaceholder, formMessage }) => {

    const { setCustomAlert } = useFeedBack();

    const [name, setName] = useState('');

    const [{ data, loading }, createFavorite] = useAxios({ url: `/my-account/favorites`, method: 'POST' }, { manual: true, useCache: false });

    useEffect(() => {
        if (data) {
            setCustomAlert({
                show: true,
                message: "El registro ha sido creado exitosamente.",
                severity: "success",
                title: "Operación Exitosa",
            });
            onHide?.();
        }
    }, [data])

    const handleSubmit = (e) => {
        e?.preventDefault?.();

        createFavorite({
            data: {
                type,
                data: JSON.stringify(dataToSave),
                name
            }
        });
    }

    return (
        <Modal show={show} onHide={() => onHide?.()}>
            <Modal.Header closeButton>
                <Modal.Title>Crear Favorito</Modal.Title>
            </Modal.Header>
            <Modal.Body>
                <form onSubmit={handleSubmit}>
                    <div className="row">
                        <div className="col-md-12">
                            <div className="form-group">
                                <label className="text-primary">Nombre</label>
                                <input
                                    type="text"
                                    className="form-control"
                                    value={name}
                                    onChange={(e) => setName(e.target.value)}
                                    placeholder={namePlaceholder}
                                />
                            </div>
                        </div>
                    </div>
                    <br />
                    <small>
                        {formMessage || ''}
                    </small>
                    <br />
                    <br />
                    <div className="text-center">
                        <button className="btn btn-primary" disabled={loading}>
                            {
                                loading ?
                                    'Cargando'
                                    :
                                    'Guardar'
                            }
                        </button>
                    </div>
                </form>
            </Modal.Body>
        </Modal>
    )
}

export default CreateFavoriteModal;