import { useEffect, useState } from "react";
import { useFeedBack } from "../../../context/FeedBackContext";
import useAxios from "../../../hooks/useAxios";
import { Tab, Tabs } from "react-bootstrap";

const TravelConfig = () => {

    const { setCustomAlert } = useFeedBack();

    const [data, setData] = useState({
        days_travel_air: 1,
        days_travel_ground: 1,
        days_taxi: 1,
        days_accommodation: 1
    });

    const [{ data: record }] = useAxios({ url: `/travel-config`, }, { useCache: false });

    const [{ data: updateData, loading }, updateRecord] = useAxios({ url: `/travel-config`, method: 'PUT' }, { manual: 'true', useCache: false });

    useEffect(() => {
        if (record) {
            setData({
                days_travel_air: record?.data?.days_travel_air,
                days_travel_ground: record?.data?.days_travel_ground,
                days_taxi: record?.data?.days_taxi,
                days_accommodation: record?.data?.days_accommodation,
            });
        }
    }, [record])

    useEffect(() => {
        if (updateData) {
            setCustomAlert({
                show: true,
                message: "El registro ha sido actualizado exitosamente.",
                severity: "success",
                title: "Operación Exitosa",
            });
        }
    }, [updateData]);

    const handleChange = (e) => {
        setData((oldData) => {
            return {
                ...oldData,
                [e.target.name]: e.target.value
            }
        })
    }

    const handleSubmit = (e) => {
        e?.preventDefault?.();
        updateRecord({ data });
    }

    return (
        <div>
            <div className="card">
                <div className="card-header">
                    <div>
                        <h3>
                            Configuración de las solicitudes
                        </h3>
                        <small>
                            Ingresa el número de días de anticipación en los cuales se puede pedir una solicitud
                        </small>
                    </div>
                </div>
            </div>
            <div className="card">
                <form onSubmit={handleSubmit}>
                    <Tabs
                        defaultActiveKey='travel'
                        className=""
                    >
                        <Tab className="card-body" eventKey="travel" title="✈️ Datos del Viaje">
                            <div className="row">
                                <div className="col-md-6">
                                    <div className="form-group">
                                        <label className="text-primary">Días para los viajes con vuelos</label>
                                        <input
                                            type="number"
                                            className="form-control"
                                            placeholder="Viajes de vuelo"
                                            value={data?.days_travel_air}
                                            name="days_travel_air"
                                            onChange={handleChange}
                                        />
                                    </div>
                                </div>
                                <div className="col-md-6">
                                    <div className="form-group">
                                        <label className="text-primary">Días para los viajes terrestres</label>
                                        <input
                                            type="number"
                                            className="form-control"
                                            placeholder="Viajes terrestres"
                                            value={data?.days_travel_ground}
                                            name="days_travel_ground"
                                            onChange={handleChange}
                                        />
                                    </div>
                                </div>
                            </div>
                        </Tab>
                        <Tab className="card-body" eventKey="accommodation" title="🏨 Datos del Hospedaje">
                            <div className="form-group">
                                <label className="text-primary">Días para los hospedajes</label>
                                <input
                                    type="number"
                                    className="form-control"
                                    placeholder="Hospedajes"
                                    value={data?.days_accommodation}
                                    name="days_accommodation"
                                    onChange={handleChange}
                                />
                            </div>
                        </Tab>
                        <Tab className="card-body" eventKey="taxi" title="🚖 Datos del taxi">
                            <div className="form-group">
                                <label className="text-primary">Días para los taxis</label>
                                <input
                                    type="number"
                                    className="form-control"
                                    placeholder="taxis"
                                    value={data?.days_taxi}
                                    name="days_taxi"
                                    onChange={handleChange}
                                />
                            </div>
                        </Tab>
                    </Tabs>
                    <div className="card-footer text-end">
                        <button className="btn btn-primary" disabled={loading}>
                            {
                                loading ?
                                    'Cargando'
                                    :
                                    'Actualizar'
                            }
                        </button>
                    </div>
                </form>
            </div>
        </div >
    )
}

export default TravelConfig;