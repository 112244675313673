import swal from "sweetalert";
import useAxios from "../../hooks/useAxios";
import DateFormatter from "../DateFormatter";
import { BsFillTrashFill } from "react-icons/bs";

const FavoriteCard = ({ favorite, onHandleFavorite, onDelete }) => {

    const [{ data: response, loading }, deleteFavorite] = useAxios({ url: `/my-account/favorites/${favorite?.id}`, method: 'DELETE' }, { manual: true, useCache: false });

    const handleDelete = () => {
        swal({
            title: "¿Estas seguro/a?",
            text: "¿Quieres eliminar este favorito?",
            icon: "warning",
            buttons: true,
            dangerMode: true,
        }).then(async (willDelete) => {
            if (willDelete) {

                try {
                    const favoriteDeletedResponse = await deleteFavorite?.();
                    onDelete?.(favorite);
                } catch (error) {

                }
            };
        })
    }

    return (
        <li className="card" style={{ cursor: 'pointer' }} onClick={() => onHandleFavorite?.(favorite)}>
            <div className="card-header">
                <div>
                    <h4>
                        {favorite?.name}
                    </h4>
                    <DateFormatter value={favorite?.createdAt} dateFormat='d/MM/Y' />
                </div>
                <button className="btn btn-danger btn-xs" onClick={handleDelete}>
                    {
                        loading ?
                            <div className="spinner" style={{ width: 15, height: 15 }}>
                                <div className="double-bounce1 bg-light"></div>
                                <div className="double-bounce2 bg-light"></div>
                            </div>
                            :

                            <BsFillTrashFill style={{ fontSize: 15 }} />
                    }
                </button>
            </div>
        </li>
    )
}

export default FavoriteCard;