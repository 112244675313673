import { useEffect, useState } from "react";
import { Tab, Tabs } from "react-bootstrap";
import useCostCenters from "../../hooks/useCostCenters";
import { format } from "date-fns";
import useZones from "../../hooks/useZones";
import DetailsCard from "../../components/DetailsCard";
import useAxios from "../../hooks/useAxios";
import { GiAirplaneDeparture } from "react-icons/gi";
import { FaHotel, FaTaxi } from "react-icons/fa";
import { monthNames, statuses, weekDaysNames } from "../../util/consts";
import { useSearchParams } from "react-router-dom";


const Dashboard = () => {

    let [searchParams, setSearchParams] = useSearchParams();

    const [filterBy, setFilterBy] = useState('costCenters');
    const [filterDatesBy, setFilterDatesBy] = useState('month');

    const [filters, setFilters] = useState({
        monthAndYear: `${format(new Date(), 'yyyy-MM')}`,
        start: '',
        end: '',
        costCenterIds: [],
        zoneIds: []
    });

    const [{ costCenters, loading: costCentersLoading }, getCostCenters] = useCostCenters({ params: { perPage: 500, page: 1 }, options: { useCache: false } });
    const [{ zones, loading: zonesLoading }, getZones] = useZones({ params: { perPage: 500, page: 1 }, options: { useCache: false } });

    const [{ data, loading }, getData] = useAxios({ url: `/dashboard`, params: filters }, { useCache: false });

    useEffect(() => {
        var defaultFilters = { ...filters };
        Object.keys(filters).map((keyName) => {
            if (searchParams.get(keyName)) {
                defaultFilters[keyName] = searchParams.get(keyName) ?? '';
            }
        });
        setFilters(defaultFilters);
    }, [])

    useEffect(() => {
        setSearchParams(filters);
    }, [filters])

    useEffect(() => {
        if (filterDatesBy === 'month') {
            setFilters((oldFilters) => {
                return {
                    ...oldFilters,
                    start: '',
                    end: '',
                    monthAndYear: `${format(new Date(), 'yyyy-MM')}`
                }
            })
        }

        if (filterDatesBy === 'dates') {
            setFilters((oldFilters) => {
                return {
                    ...oldFilters,
                    monthAndYear: '',
                    start: `${format(new Date(), 'yyyy-MM-dd')}`,
                    end: `${format(new Date(), 'yyyy-MM-dd')}`
                }
            })
        }
    }, [filterDatesBy])

    useEffect(() => {
        if (filterBy === 'costCenters') {
            setFilters((oldFilters) => {
                return {
                    ...oldFilters,
                    zoneIds: []
                }
            })
        }

        if (filterBy == 'zones') {
            setFilters((oldFilters) => {
                return {
                    ...oldFilters,
                    costCenterIds: []
                }
            });
        }
    }, [filterBy])

    const handleChange = (e) => {
        setFilters((oldFilters) => {
            return {
                ...oldFilters,
                [e.target.name]: e.target.value
            }
        })
    }

    return (
        <div>
            <div className="row">
                <div className="col-md-6">
                    <div className="card p-3">
                        <Tabs
                            activeKey={filterBy}
                            onSelect={(k) => setFilterBy(k)}
                            className="mb-3"
                        >
                            <Tab eventKey="costCenters" title="Centros de costo">
                                <div className="form-group">
                                    <select
                                        className="form-control"
                                        value={filters?.costCenterIds}
                                        name="costCenterIds"
                                        onChange={handleChange}
                                    >
                                        <option value="">Todos</option>
                                        {costCenters?.map((costCenter, i) => <option value={costCenter?.id} key={i}>
                                            {costCenter?.name}
                                        </option>
                                        )}
                                    </select>
                                </div>
                            </Tab>
                            <Tab eventKey="zones" title="Zonas">
                                <div className="form-group">
                                    <select
                                        className="form-control"
                                        name="zoneIds"
                                        value={filters?.zoneIds}
                                        onChange={handleChange}
                                    >
                                        <option value="">Todas</option>
                                        {zones?.map((zone, i) => {
                                            return (
                                                <option value={zone.id} key={i}>
                                                    {zone?.name}
                                                </option>
                                            )
                                        }
                                        )}
                                    </select>
                                </div>
                            </Tab>
                        </Tabs>
                    </div>
                </div>
                <div className="col-md-6">
                    <div className="card p-3">
                        <Tabs
                            activeKey={filterDatesBy}
                            onSelect={(k) => setFilterDatesBy(k)}
                            className="mb-3"
                        >
                            <Tab eventKey="month" title="Mes y año">
                                <label>Mes y Año</label>
                                <input
                                    type="month"
                                    id="start"
                                    name="start"
                                    className="form-control"
                                    value={filters?.monthAndYear}
                                    onChange={(e) => setFilters((oldFilters) => {
                                        return {
                                            ...oldFilters,
                                            monthAndYear: e.target.value
                                        }
                                    })}
                                />
                            </Tab>
                            <Tab eventKey="dates" title="Rango de fechas">
                                <div className="row">
                                    <div className="col-md-6">
                                        <label>Desde</label>
                                        <input
                                            type="date"
                                            id="start"
                                            name="start"
                                            className="form-control"
                                            value={filters?.start}
                                            onChange={(e) => setFilters((oldFilters) => {
                                                return {
                                                    ...oldFilters,
                                                    start: e.target.value
                                                }
                                            })}
                                        />
                                    </div>
                                    <div className="col-md-6">
                                        <label>Hasta</label>
                                        <input
                                            type="date"
                                            id="end"
                                            name="end"
                                            className="form-control"
                                            value={filters?.end}
                                            onChange={(e) => setFilters((oldFilters) => {
                                                return {
                                                    ...oldFilters,
                                                    end: e.target.value
                                                }
                                            })}
                                        />
                                    </div>
                                </div>
                            </Tab>
                        </Tabs>
                    </div>
                </div>

                <div className="row">
                    <div className="col-xl-12">
                        <div className="card">
                            <div className="card-header">
                                <h3>
                                    🧾 Solicitudes
                                </h3>
                            </div>
                            <div className="card-body">
                                {
                                    loading ?
                                        <div className="spinner">
                                            <div className="double-bounce1 bg-primary"></div>
                                            <div className="double-bounce2 bg-primary"></div>
                                        </div>
                                        :
                                        <div className="d-md-flex justify-content-between align-items-center animate__animated animate__fadeIn">
                                            {
                                                statuses.map((status, i) => {
                                                    return (
                                                        <div className="text-center" key={i}>
                                                            <h7 className={`text-${status?.variantColor}`}>{status?.pluralName}</h7>
                                                            <p>{data?.travelsCountByStatus?.[status?.id]?.count || 0}</p>
                                                        </div>
                                                    )
                                                })
                                            }
                                            <div className="text-center">
                                                <h7 className={`text-black`}>Total</h7>
                                                <p>{data?.travelsCountByStatusTotal || 0}</p>
                                            </div>
                                        </div>
                                }
                            </div>
                        </div>
                    </div>
                    <div className="col-xl-6">
                        <div className="card">
                            <div className="card-header">
                                <h3>
                                    🚖 taxis
                                </h3>
                            </div>
                            <div className="card-body">
                                {
                                    loading ?
                                        <div className="spinner">
                                            <div className="double-bounce1 bg-primary"></div>
                                            <div className="double-bounce2 bg-primary"></div>
                                        </div>
                                        :
                                        <div className="d-md-flex justify-content-between align-items-center animate__animated animate__fadeIn">
                                            {
                                                statuses.map((status, i) => {
                                                    return (
                                                        <div className="text-center" key={i}>
                                                            <h7 className={`text-${status?.variantColor}`}>{status?.pluralName}</h7>
                                                            <p>{data?.counts?.taxi?.[status?.id] || 0}</p>
                                                        </div>
                                                    )
                                                })
                                            }
                                            <div className="text-center">
                                                <h7 className={`text-black`}>Total</h7>
                                                <p>{data?.counts?.taxi?.total || 0}</p>
                                            </div>
                                        </div>
                                }
                            </div>
                        </div>
                    </div>
                    <div className="col-xl-6">
                        <div className="card">
                            <div className="card-header">
                                <h3>
                                    ✈️ Viajes
                                </h3>
                            </div>
                            <div className="card-body">
                                {
                                    loading ?
                                        <div className="spinner">
                                            <div className="double-bounce1 bg-primary"></div>
                                            <div className="double-bounce2 bg-primary"></div>
                                        </div>
                                        :
                                        <div className="d-md-flex justify-content-between align-items-center animate__animated animate__fadeIn">
                                            {
                                                statuses.map((status, i) => {
                                                    return (
                                                        <div className="text-center" key={i}>
                                                            <h7 className={`text-${status?.variantColor}`}>{status?.pluralName}</h7>
                                                            <p>{data?.counts?.travel?.[status?.id] || 0}</p>
                                                        </div>
                                                    )
                                                })
                                            }
                                            <div className="text-center">
                                                <h7 className={`text-black`}>Total</h7>
                                                <p>{data?.counts?.travel?.total || 0}</p>
                                            </div>
                                        </div>
                                }
                            </div>
                        </div>
                    </div>
                    <div className="col-xl-6">
                        <div className="card">
                            <div className="card-header">
                                <h3>
                                    🏨 Hospedajes
                                </h3>
                            </div>
                            <div className="card-body">
                                {
                                    loading ?
                                        <div className="spinner">
                                            <div className="double-bounce1 bg-primary"></div>
                                            <div className="double-bounce2 bg-primary"></div>
                                        </div>
                                        :
                                        <div className="d-md-flex justify-content-between align-items-center animate__animated animate__fadeIn">
                                            {
                                                statuses.map((status, i) => {
                                                    return (
                                                        <div className="text-center" key={i}>
                                                            <h7 className={`text-${status?.variantColor}`}>{status?.pluralName}</h7>
                                                            <p>{data?.counts?.accommodation?.[status?.id] || 0}</p>
                                                        </div>
                                                    )
                                                })
                                            }
                                            <div className="text-center">
                                                <h7 className={`text-black`}>Total</h7>
                                                <p>{data?.counts?.accommodation?.total || 0}</p>
                                            </div>
                                        </div>
                                }
                            </div>
                        </div>
                    </div>
                    <div className="col-xl-6">
                        <div className="card">
                            <div className="card-header">
                                <h3>
                                    📋 Totales
                                </h3>
                            </div>
                            <div className="card-body">
                                {
                                    loading ?
                                        <div className="spinner">
                                            <div className="double-bounce1 bg-primary"></div>
                                            <div className="double-bounce2 bg-primary"></div>
                                        </div>
                                        :
                                        <div className="d-md-flex justify-content-between align-items-center animate__animated animate__fadeIn">
                                            {
                                                statuses.map((status, i) => {
                                                    return (
                                                        <div className="text-center" key={i}>
                                                            <h7 className={`text-${status?.variantColor}`}>{status?.pluralName}</h7>
                                                            <p>{data?.counts?.totals?.[status?.id] || 0}</p>
                                                        </div>
                                                    )
                                                })
                                            }
                                            <div className="text-center">
                                                <h7 className={`text-black`}>Total</h7>
                                                <p>{data?.counts?.totals?.total || 0}</p>
                                            </div>
                                        </div>
                                }
                            </div>
                        </div>
                    </div>

                    <div className="col-md-12">
                        <div className="card">
                            <div className="card-header">
                                <h3>
                                    🏨 Solicitudes por centro de costo
                                </h3>
                            </div>
                            <div className="card-body">
                                {
                                    loading ?
                                        <div className="spinner">
                                            <div className="double-bounce1 bg-primary"></div>
                                            <div className="double-bounce2 bg-primary"></div>
                                        </div>
                                        :
                                        <div className="animate__animated animate__fadeIn">
                                            <ul>
                                                <li className="row align-items-center">
                                                    <h6 className="col-md-4">Centro de costo</h6>
                                                    <h6 className="col-md-2 text-center">🚖 Taxis</h6>
                                                    <h6 className="col-md-2 text-center">🏨 Hospedajes</h6>
                                                    <h6 className="col-md-2 text-center">✈️ Viajes</h6>
                                                    <h6 className="col-md-2 text-center">📋 Total</h6>
                                                </li>
                                                {
                                                    data?.costCenters &&
                                                    Object?.keys(data?.costCenters)?.map((costCenterName, i) => {
                                                        return (
                                                            <li key={i} className="row align-items-center">
                                                                <p className="col-md-4">{costCenterName}</p>
                                                                <p className="col-md-2 text-center">{data?.costCenters?.[costCenterName]?.taxi || 0}</p>
                                                                <p className="col-md-2 text-center">{data?.costCenters?.[costCenterName]?.accommodation || 0}</p>
                                                                <p className="col-md-2 text-center">{data?.costCenters?.[costCenterName]?.travel || 0}</p>
                                                                <p className="col-md-2 text-center text-dark">
                                                                    <b>
                                                                        {data?.costCenters?.[costCenterName]?.total || 0}
                                                                    </b>
                                                                </p>
                                                            </li>
                                                        )
                                                    })
                                                }
                                            </ul>
                                        </div>
                                }
                            </div>
                        </div>
                    </div>

                    <div className="col-md-12">
                        <div className="card">
                            <div className="card-header">
                                <h3>
                                    🔥 Más concurridos
                                </h3>
                            </div>
                            <div className="card-body">
                                {
                                    loading ?
                                        <div className="spinner">
                                            <div className="double-bounce1 bg-primary"></div>
                                            <div className="double-bounce2 bg-primary"></div>
                                        </div>
                                        :
                                        <div className="animate__animated animate__fadeIn">
                                            <div className="row">
                                                <div className="col-md-3 text-center">
                                                    <h6>Hora</h6>
                                                    <h4>{data?.mostWanted?.hour?.hour}</h4>
                                                    <p>{data?.mostWanted?.hour?.count || 'Sin'} Solicitudes</p>
                                                </div>
                                                <div className="col-md-3 text-center">
                                                    <h6>Día de la semana</h6>
                                                    <h4>{weekDaysNames?.[data?.mostWanted?.weekday?.day]?.name}</h4>
                                                    <p>{data?.mostWanted?.weekday?.count || 'Sin'} Solicitudes</p>
                                                </div>
                                                <div className="col-md-3 text-center">
                                                    <h6>Día del mes</h6>
                                                    <h4>{data?.mostWanted?.day?.day}</h4>
                                                    <p>{data?.mostWanted?.day?.count || 'Sin'} Solicitudes</p>
                                                </div>
                                                <div className="col-md-3 text-center">
                                                    <h6>Mes</h6>
                                                    <h4>{monthNames?.[Number(data?.mostWanted?.month?.month) - 1]?.name}</h4>
                                                    <p>{data?.mostWanted?.month?.count || 'Sin'} Solicitudes</p>
                                                </div>
                                            </div>
                                        </div>
                                }
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default Dashboard;