import { useEffect, useState } from "react";
import useAxios from "../../../hooks/useAxios";
import { useFeedBack } from "../../../context/FeedBackContext";
import { useNavigate, useParams } from "react-router-dom";

const ConfigEmailsEdit = () => {

    const { id } = useParams();

    const { setCustomAlert } = useFeedBack();

    const navigate = useNavigate();

    const [data, setData] = useState({
        email: '',
        for: ''
    });

    const [{ data: record }] = useAxios({ url: `/notification-emails/${id}`, }, { useCache: false });

    const [{ data: updateData, loading }, updateRecord] = useAxios({ url: `/notification-emails/${id}`, method: 'PUT' }, { manual: 'true', useCache: false });

    useEffect(() => {
        if (record) {
            setData({
                email: record?.data?.email,
                for: record?.data?.for,
            });
        }
    }, [record])

    useEffect(() => {
        if (updateData) {
            setCustomAlert({
                show: true,
                message: "El registro ha sido actualizado exitosamente.",
                severity: "success",
                title: "Operación Exitosa",
            });
            navigate("/configuracion/emails");
        }
    }, [updateData]);

    const handleChange = (e) => {
        setData((oldData) => {
            return {
                ...oldData,
                [e.target.name]: e.target.value
            }
        })
    }

    const handleSubmit = (e) => {
        e?.preventDefault?.();
        updateRecord({ data });
    }

    return (
        <div>
            <div className="card">
                <form onSubmit={handleSubmit}>
                    <div className="card-header">
                        <div>
                            <h3>
                                Edita email de notificación
                            </h3>
                            <small>
                                Estos emails se usan para recibir notificaciones cuando se cree una solicitud que contenga una peticion de: <b>Taxi, Viaje o Hospedaje.</b>
                            </small>
                        </div>
                    </div>
                    <div className="card-body">
                        <div className="row">
                            <div className="col-md-6">
                                <div className="form-group">
                                    <label className="text-primary">Email</label>
                                    <input
                                        type="text"
                                        className="form-control"
                                        value={data?.email}
                                        onChange={handleChange}
                                        name="email"
                                        placeholder="Email"
                                    />
                                </div>
                            </div>
                            <div className="col-md-6">
                                <div className="form-group">
                                    <label className="text-primary">Email Para Notificar:</label>
                                    <select name="for" className="form-control" value={data?.for} onChange={handleChange}>
                                        <option value="">Seleccione un opción</option>
                                        <option value="travel">Viajes</option>
                                        <option value="accommodation">Hospedajes</option>
                                        <option value="taxi">Taxis</option>
                                    </select>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="card-footer text-end">
                        <button className="btn btn-primary" disabled={loading}>
                            {
                                loading ?
                                    'Cargando'
                                    :
                                    'Actualizar'
                            }
                        </button>
                    </div>
                </form>
            </div>
        </div >
    )
}

export default ConfigEmailsEdit;