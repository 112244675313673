import Dashboard from "../pages/private/Dashboard";
import SystemInfo from "./SystemInfo";

import { AiOutlineDashboard, AiOutlineOrderedList, AiFillSetting } from "react-icons/ai";
import { GiAirplaneDeparture } from "react-icons/gi";
import { BiUser } from "react-icons/bi";
import TravelsCreate from "../pages/private/travels/TravelsCreate";
import MyTravels from "../pages/private/my-account/MyTravels";
import MyTravelsDetail from "../pages/private/my-account/MyTravelsDetail";
import Travels from "../pages/private/travels/Travels";
import TravelsDetail from "../pages/private/travels/TravelsDetail";
import ConfigEmails from "../pages/private/configuration/ConfigEmails";
import ConfigEmailsCreate from "../pages/private/configuration/ConfigEmailsCreate";
import ConfigEmailsEdit from "../pages/private/configuration/ConfigEmailsEdit";
import Instructions from "../pages/private/instructions/Instructions";
import TravelConfig from "../pages/private/configuration/TravelConfig";
import Exceptions from "../pages/private/exceptions/Exceptions";
import ExceptionsHistory from "../pages/private/exceptions/ExceptionsHistory";

const createLink = (
    title,
    hidden,
    component,
    Icon,
    path,
    permissions,
    children
) => ({ title, hidden, component, Icon, path, permissions, children });

const { systemCode } = SystemInfo;

export const mainPermissions = {
    travels: [`${systemCode}-view-travels`, `${systemCode}-delete-travels`],
    notificationEmails: [`${systemCode}-view-notification-emails`, `${systemCode}-create-notification-emails`, `${systemCode}-update-notification-emails`, `${systemCode}-delete-notification-emails`],
    instructions: [`${systemCode}-update-instructions`],
    exceptions: [`${systemCode}-update-travel-exceptions`],
    travelConfig: [`${systemCode}-view-travel-config`, `${systemCode}-update-travel-config`]
}

const MenuLinks = [
    createLink('DashBoard', null, <Dashboard />, AiOutlineDashboard, '/dashboard', null),

    createLink('Solicitar viaje', null, <TravelsCreate />, GiAirplaneDeparture, '/solicitar-viaje', null),

    createLink('Listado de viajes', null, <Travels />, AiOutlineOrderedList, '/listado-de-viajes', mainPermissions?.travels[0]),
    createLink('Detalle del viaje', true, <TravelsDetail />, AiOutlineOrderedList, '/listado-de-viajes/:id', mainPermissions?.travels[0]),



    createLink('Configuración', null, null, AiFillSetting, '/configuracion', [...mainPermissions?.notificationEmails, ...mainPermissions?.instructions, ...mainPermissions?.travelConfig, ...mainPermissions?.exceptions], [
        createLink('Emails', null, <ConfigEmails />, AiOutlineOrderedList, '/configuracion/emails', mainPermissions?.notificationEmails?.[0]),
        createLink('Crear Emails', true, <ConfigEmailsCreate />, AiOutlineOrderedList, '/configuracion/emails/crear', mainPermissions?.notificationEmails?.[1]),
        createLink('Editar Emails', true, <ConfigEmailsEdit />, AiOutlineOrderedList, '/configuracion/emails/:id', mainPermissions?.notificationEmails?.[2]),

        createLink('Condiciones de las solicitudes', null, <Instructions />, AiOutlineOrderedList, '/configuracion/condiciones', mainPermissions?.instructions?.[0]),

        createLink('Configuración de días', null, <TravelConfig />, AiOutlineOrderedList, '/configuracion/general', mainPermissions?.travelConfig?.[0]),

        createLink('Excepciones', null, <Exceptions />, AiOutlineOrderedList, '/configuracion/excepciones', mainPermissions?.exceptions?.[0]),

        createLink('Historial de excepciones', null, <ExceptionsHistory />, AiOutlineOrderedList, '/configuracion/hisorial-de-excepciones', mainPermissions?.exceptions?.[0])
    ]),

    createLink('Mi Cuenta', null, null, BiUser, '/mi-cuenta', null, [
        createLink('Mis Solicitudes', null, <MyTravels />, AiOutlineOrderedList, '/mi-cuenta/mis-viajes'),
        createLink('Mis Viajes', true, <MyTravelsDetail />, AiOutlineOrderedList, '/mi-cuenta/mis-viajes/:id')
    ]),

];

export default MenuLinks;