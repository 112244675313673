import { useEffect, useState } from "react";
import { Button, Modal, Table } from "react-bootstrap";
import dateOnlyConverter from "../util/dateOnlyConverter";
import DateFormatter from "./DateFormatter";
import { MdEdit } from "react-icons/md";
import AsyncSelect from "react-select/async";
import useCommunes from "../hooks/useCommunes";
import handleLoadSelectOptions from "../util/loadSelectValues";
import mapValues from "../util/mapValues";
import useAxios from "../hooks/useAxios";
import { useFeedBack } from "../context/FeedBackContext";


const TravelSummary = ({ travel, onExport, loadingExport, canEdit, onUpdate }) => {
  const [showPersonsModal, setShowPersonsModal] = useState(false);

  const [valuesToEdit, setValuesToEdit] = useState({
    title: '',
    date: '',
    hour: '',
    comuneFrom: '',
    comuneTo: '',
    instance: ''
  });

  const { setCustomAlert } = useFeedBack();

  const [{ data, loading }, updateValues] = useAxios({ url: `/travels/${travel?.id}/travel`, method: 'PUT' }, { manual: true, useCache: false });

  const [{ communes, loading: communesLoading }, getCommunes] = useCommunes({
    params: { perPage: 100 }, options: { manual: true, useCache: false },
  });

  const [showModal, setShowModal] = useState(false);

  useEffect(() => {
    if (data) {
      setCustomAlert({
        show: true,
        message: "El registro ha sido actualizado exitosamente.",
        severity: "success",
        title: "Operación Exitosa",
      });
      onUpdate?.(data?.data);
      setShowModal(false);
    }
  }, [data])

  if (travel?.travel !== "si") return null;

  const handleClose = () => {
    setShowPersonsModal((oldValue) => !oldValue);
  };

  const handleEdit = (valueToEdit) => {

    if (valueToEdit === 'start') setValuesToEdit({
      title: 'Editar datos del viaje de ida',
      date: DateFormatter({
        value: dateOnlyConverter(travel?.travelStart),
        dateFormat: "yyyy-MM-dd",
      }),
      hour: travel?.travelStartHour,
      comuneFrom: { label: travel?.travelStartFromCommune?.name, value: travel?.travelStartFromCommune?.id },
      comuneTo: { label: travel?.travelStartToCommune?.name, value: travel?.travelStartToCommune?.id },
      instance: 'start'
    });

    if (valueToEdit === 'end') setValuesToEdit({
      title: 'Editar datos del viaje de ida de vuelta',
      date: DateFormatter({
        value: dateOnlyConverter(travel?.travelEnd),
        dateFormat: "yyyy-MM-dd",
      }),
      hour: travel?.travelEndHour,
      comuneFrom: { label: travel?.travelEndFromCommune?.name, value: travel?.travelEndFromCommune?.id },
      comuneFrom: { label: travel?.travelEndToCommune?.name, value: travel?.travelEndToCommune?.id },
      instance: 'end'
    });

    setShowModal(true);
  }

  const handleChange = (e) => {
    setValuesToEdit((oldValues) => {
      return {
        ...oldValues,
        [e.target.name]: e.target.value
      }
    });
  }

  const handleSubmit = (e) => {
    e?.preventDefault?.();

    const { title, ...rest } = valuesToEdit;

    updateValues({
      data: {
        ...rest,
        comuneFrom: rest?.comuneFrom?.value,
        comuneTo: rest?.comuneTo?.value
      }
    });
  }


  return (
    <>
      <div
        className="card"
        style={{ borderTopLeftRadius: 0, borderTopRightRadius: 0 }}
      >
        <div className="card-header">
          <div className="d-flex align-items-center justify-content-between w-100">
            <h5>✈️ Datos del servicio de viajes</h5>
            <button
              className="btn btn-xs btn-success"
              onClick={() => onExport?.("travel", "viajes")}
            >
              {loadingExport ? "cargando..." : "Exportar a excel"}
            </button>
          </div>
        </div>
        <div className="card-body">
          <div className="row">
            <div className="col-md-6 mb-3">
              <label className="text-primary">Usuario Solicitante</label>
              <input
                readOnly
                type="text"
                value={travel?.user?.name || "--"}
                className="form-control"
              />
            </div>
            <div className="col-md-6 mb-3">
              <label className="text-primary d-flex justify-content-between align-items-center">
                Número de personas
                <span
                  type="button"
                  className="text-primary"
                  onClick={handleClose}
                >
                  Ver Detalles
                </span>
              </label>
              <input
                readOnly
                type="text"
                value={travel?.travelPersons?.length}
                className="form-control"
              />
            </div>
            <div className="col-md-6 mb-3">
              <label className="text-primary">Modalidad Del viaje</label>
              <input
                readOnly
                type="text"
                value={travel?.travelModality || "--"}
                className="form-control"
              />
            </div>
            <div className="col-md-6 mb-3">
              <label className="text-primary">
                ¿LLevan equipaje de bodega?
              </label>
              <input
                readOnly
                type="text"
                value={travel?.travelBaggage || "--"}
                className="form-control"
              />
            </div>
            <div className="col-md-12 mb-3 mt-4">
              <div className="d-flex align-items-center justify-content-between">
                <h3>Datos del viaje de ida</h3>
                {
                  canEdit &&
                  <button onClick={() => handleEdit('start')} className="btn btn-xs btn-primary">
                    Editar  <MdEdit />
                  </button>
                }
              </div>

            </div>
            <div className="col-md-6 mb-3">
              <label className="text-primary">Fecha de salida</label>
              <div className="row align-items-center">
                <div className="col-md-5">
                  <input
                    readOnly
                    type="text"
                    value={DateFormatter({
                      value: dateOnlyConverter(travel?.travelStart),
                      dateFormat: "d/MM/yyyy",
                    })}
                    className="form-control"
                  />
                </div>
                <div className="col-md-2 text-primary">Hora:</div>
                <div className="col-md-5">
                  <input
                    readOnly
                    type="text"
                    value={travel?.travelStartHour}
                    className="form-control"
                  />
                </div>
              </div>
            </div>
            <div className="col-md-3 mb-3">
              <label className="text-primary">Desde</label>
              <input
                readOnly
                type="text"
                value={travel?.travelStartFromCommune?.name || "--"}
                className="form-control"
              />
            </div>
            <div className="col-md-3 mb-3">
              <label className="text-primary">Hasta</label>
              <input
                readOnly
                type="text"
                value={travel?.travelStartToCommune?.name || "--"}
                className="form-control"
              />
            </div>
            <div className="col-md-12 mb-3 mt-4">
              <div className="d-flex align-items-center justify-content-between">
                <h3>Datos del viaje de vuelta</h3>
                {
                  canEdit && travel?.travelBack ?
                    <button onClick={() => handleEdit('end')} className="btn btn-xs btn-primary">
                      Editar  <MdEdit />
                    </button>
                    :
                    null
                }
              </div>
            </div>
            {travel?.travelBack ? (
              <>
                <div className="col-md-6 mb-3">
                  <label className="text-primary">Fecha de salida</label>
                  <div className="row align-items-center">
                    <div className="col-md-5">
                      <input
                        readOnly
                        type="text"
                        value={DateFormatter({
                          value: dateOnlyConverter(travel?.travelEnd),
                          dateFormat: "d/MM/yyyy",
                        })}
                        className="form-control"
                      />
                    </div>
                    <div className="col-md-2 text-primary">Hora:</div>
                    <div className="col-md-5">
                      <input
                        readOnly
                        type="text"
                        value={travel?.travelEndHour}
                        className="form-control"
                      />
                    </div>
                  </div>
                </div>
                <div className="col-md-3 mb-3">
                  <label className="text-primary">Desde</label>
                  <input
                    readOnly
                    type="text"
                    value={travel?.travelEndFromCommune?.name || "--"}
                    className="form-control"
                  />
                </div>
                <div className="col-md-3 mb-3">
                  <label className="text-primary">Hasta</label>
                  <input
                    readOnly
                    type="text"
                    value={travel?.travelEndToCommune?.name || "--"}
                    className="form-control"
                  />
                </div>
              </>
            ) : (
              <div className="col-md-12">
                <p className="text-danger">
                  No necesitan boletos para un viaje de vuelta.
                </p>
              </div>
            )}
            <div className="col-md-12 mb-3">
              <label className="text-primary">Observaciones del Viaje</label>
              <textarea
                rows={4}
                style={{ minHeight: 150 }}
                readOnly
                type="text"
                value={travel?.travelObservations}
                className="form-control"
              />
            </div>
          </div>
        </div>
      </div>
      <Modal show={showPersonsModal} onHide={handleClose} size="xl">
        <Modal.Header closeButton>
          <Modal.Title>Detalle de las personas</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <div className="table-responsive">
            <Table striped>
              <thead>
                <tr>
                  <th style={{ fontSize: 12 }}>#</th>
                  <th style={{ fontSize: 12 }}>Nombre</th>
                  <th style={{ fontSize: 12 }}>Rut</th>
                  <th style={{ fontSize: 12 }}>Teléfono</th>
                  <th style={{ fontSize: 12 }}>Email</th>
                  <th style={{ fontSize: 12 }}>Centro de costo</th>
                  <th style={{ fontSize: 12 }}>Cobro por formular</th>
                  <th style={{ fontSize: 12 }}>Fecha de nacimiento</th>
                </tr>
              </thead>
              <tbody>
                {travel?.travelPersons?.map((person, i) => {
                  return (
                    <tr>
                      <td>{i + 1}</td>
                      <td>{person?.name || "--"}</td>
                      <td>{person?.rut || "--"}</td>
                      <td>{person?.phoneNumber || "--"}</td>
                      <td>{person?.email || "--"}</td>
                      <td>{person?.costCenter?.name || "--"}</td>
                      <td style={{ textTransform: 'capitalize' }}>{person?.chargeForFormulating || "--"}</td>
                      <td>
                        {DateFormatter({
                          value: dateOnlyConverter(person?.birthday),
                          dateFormat: "d/MM/yyyy",
                        })}
                      </td>
                    </tr>
                  );
                })}
              </tbody>
            </Table>
          </div>
        </Modal.Body>
        <Modal.Footer className="text-center justify-content-center">
          <Button variant="danger" onClick={handleClose}>
            Cerrar
          </Button>
        </Modal.Footer>
      </Modal>
      <Modal show={showModal} onHide={() => setShowModal(false)} size="xl">
        <Modal.Header closeButton>
          <Modal.Title>{valuesToEdit?.title}</Modal.Title>
        </Modal.Header>
        <form onSubmit={handleSubmit}>
          <Modal.Body>
            <div className="row">
              <div className="col-md-6 mb-3">
                <label className="text-primary">
                  Fecha
                  <small className="text-danger">*</small>
                </label>
                <input
                  type="date"
                  value={valuesToEdit?.date}
                  className="form-control"
                  name="date"
                  onChange={handleChange}
                />
              </div>
              <div className="col-md-6 mb-3">
                <label className="text-primary">
                  Hora
                  <small className="text-danger">*</small>
                </label>
                <input
                  type="time"
                  name="hour"
                  onChange={handleChange}
                  value={valuesToEdit.hour}
                  required
                  className="form-control"
                />
              </div>
              <div className="col-md-6 mb-3">
                <label className="text-primary">
                  Desde
                  <small className="text-danger">*</small>
                </label>
                <AsyncSelect
                  onFocus={() => {
                    getCommunes({
                      params: { perPage: 100 }
                    });
                  }}
                  value={valuesToEdit?.comuneFrom}
                  defaultOptions={mapValues(communes)}
                  isLoading={communesLoading}
                  loadOptions={(e) =>
                    handleLoadSelectOptions(e, getCommunes, { perPage: 100 })
                  }
                  placeholder="buscar..."
                  onChange={(e) => {
                    handleChange({
                      target: {
                        value: e,
                        name: "comuneFrom",
                      },
                    });
                  }}
                />
              </div>
              <div className="col-md-6 mb-3">
                <label className="text-primary">
                  Hasta
                  <small className="text-danger">*</small>
                </label>
                <AsyncSelect
                  onFocus={() => {
                    getCommunes({
                      params: { perPage: 100 }
                    });
                  }}
                  value={valuesToEdit?.comuneTo}
                  defaultOptions={mapValues(communes)}
                  isLoading={communesLoading}
                  loadOptions={(e) =>
                    handleLoadSelectOptions(e, getCommunes, { perPage: 100 })
                  }
                  placeholder="buscar..."
                  onChange={(e) => {
                    handleChange({
                      target: {
                        value: e,
                        name: "comuneTo",
                      },
                    });
                  }}
                />
              </div>
            </div>
          </Modal.Body>
          <Modal.Footer>
            <div className="row align-items-center w-100">
              <div className="col-sm-6 mb-3 mb-sm-0">
                <button className="btn btn-danger btn-block" type="button" variant="danger" onClick={() => setShowModal(false)}>
                  Cancelar
                </button>
              </div>
              <div className="col-sm-6 mb-3 mb-sm-0">
                <button disabled={loading} className="btn btn-primary btn-block" variant="primary" >
                  {
                    loading ?
                      <div className="spinner" style={{ width: 21, height: 21 }}>
                        <div className="double-bounce1 bg-light"></div>
                        <div className="double-bounce2 bg-light"></div>
                      </div>
                      :
                      'Enviar'
                  }
                </button>
              </div>
            </div>
          </Modal.Footer>
        </form>
      </Modal>
    </>
  );
};

export default TravelSummary;
