import TableCheck from "../TableCheck";
import ImageAndName from "../../ImageAndName";
import userImg from "../../../images/profile.png";
import TravelExceptionToggleComponent from "../../TravelExceptionToggleComponent";
import DateFormatter from "../../DateFormatter";

const TravelExceptionColumns = [
    {
        Label: TableCheck,
        Component: TableCheck
    },
    {
        Label: () => 'id',
        accessor: 'id'
    },
    {
        Label: () => 'Solicitante',
        Component: ({ value }) => <ImageAndName imgValue={value?.user?.imagePath || userImg} nameValue={value?.user?.name} />
    },
    {
        Label: () => 'Centro de Costo',
        Component: ({ value }) => `${value?.user?.costCenter?.name}`
    },
    {
        Label: () => 'Correo Electrónico',
        Component: ({ value }) => `${value?.user?.email}`
    },
    {
        Label: () => 'Rut',
        Component: ({ value }) => `${value?.user?.documentNumber || '--'}`
    },
    {
        Label: () => 'Ha sido usada',
        Component: ({ value }) => <div className={`btn btn-${value?.used ? 'success' : 'danger'}`}>
            {
                value?.used ?
                    'si'
                    :
                    'no'
            }
        </div>
    },
    {
        Label: () => 'Activa',
        Component: ({ value }) => <div className={`btn btn-${value?.activate ? 'success' : 'danger'}`}>
            {
                value?.activate ?
                    'si'
                    :
                    'no'
            }
        </div>
    },
    {
        Label: () => 'Fecha de Creación',
        Component: ({ value }) => <DateFormatter value={value?.createdAt} dateFormat='d/MM/Y' />
    },
];

export default TravelExceptionColumns;

