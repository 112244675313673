import StepFour from "./StepFour";
import StepOne from "./StepOne";
import StepThree from "./StepThree";
import StepTwo from "./StepTwo";
import { useTravelCrud } from "../../../../context/TravelCrudContext";
import Stepper from "../../../Stepper/Stepper";
import { useEffect, useState } from "react";
import StepFive from "./StepFive";
import CreateFavoriteModal from "../../../Modals/CreateFavoriteModal";
import ShowFavoritesModal from "../../../Modals/ShowFavoritesModal";
import { AiFillWarning } from "react-icons/ai";
import useAxios from "../../../../hooks/useAxios";
import TravelExceptionRequestModal from "../../../TravelExceptionRequestModal";


const CreateTravelForm = () => {

    const { currentStep, canNext, setCurrentStep, data, setData, setMinDates } = useTravelCrud();

    const [show, setShow] = useState(false);

    const [canShowForm, setCanShowForm] = useState(false);

    const [accept, setAccept] = useState(false);

    const [steps, setSteps] = useState([
        { name: "1: Viaje", Component: StepTwo },
        { name: "2: Hotel", Component: StepThree },
        { name: "3: Taxi", Component: StepFour },
        { name: "4: Motivo", Component: StepFive },
    ]);

    const [{ data: instructionsData, loading: loadingInstructions }] = useAxios({ url: `/instructions` }, { useCache: false });

    const [{ }, getMinDates] = useAxios({ url: `/travel-config/calculated-dates` }, { manual: true, useCache: false });

    const handleStep = ({ stepNumber }) => {
        setCurrentStep(stepNumber);
    }

    const handleFavorite = (favorite) => {
        setData(favorite?.data);
        setShow(false);
    }

    useEffect(() => {
        getMinDates().then((response) => {
            setMinDates(response?.data);
        })
    }, [])

    return (
        <>
            {
                canShowForm ?
                    <>
                        <div className="text-end mb-4 w-100">
                            <button
                                className="btn mx-1 btn-primary btn-xs"
                                onClick={() => setShow('travel-exception-request')}>
                                Solicitar excepción de limite de fechas
                            </button>
                            <button className="btn mx-1 btn-primary btn-xs" onClick={(e) => setShow('favorites')}>
                                Mostrar favoritos
                            </button>
                            <button className="btn mx-1 btn-primary btn-xs" onClick={(e) => setShow('createFavorite')}>
                                Guardar como favorito
                            </button>
                        </div>
                        <TravelExceptionRequestModal
                            show={show === 'travel-exception-request'}
                            onHide={() => setShow(false)}
                        />
                        <ShowFavoritesModal
                            show={show === 'favorites'}
                            type='travel'
                            onHide={() => setShow(false)}
                            handleFavorite={handleFavorite}
                        />
                        <CreateFavoriteModal
                            show={show === 'createFavorite'}
                            dataToSave={data}
                            onHide={() => setShow(false)}
                            type={'travel'}
                            namePlaceholder="Ej: Casa - Trabajo"
                            formMessage="PD: Se guardarán los datos que hayas ingresado en esta solicitud actual."
                        />
                        <div className="row">
                            <div className="col-xl-12 col-xxl-12">
                                <Stepper
                                    steps={steps}
                                    currentStep={currentStep}
                                    onClickStep={handleStep}
                                />
                            </div>
                        </div>
                    </>
                    :
                    <div className="card p-5">
                        <AiFillWarning className="m-auto text-warning" style={{ fontSize: 60 }} />
                        <h3 className="text-center">
                            Aviso Importante
                        </h3>
                        <br />
                        <div dangerouslySetInnerHTML={{ __html: instructionsData?.data?.description }} />
                        <br />
                        <div className="text-center mb-3">
                            <label className="d-flex align-items-center w-100 justify-content-center">
                                <input style={{ marginRight: 5 }} type="checkbox" checked={accept} onChange={() => setAccept(old => !old)} />
                                <p className="m-0">
                                    He leido y acepto los terminos y condiciones.
                                </p>
                            </label>
                        </div>
                        <div className="text-center" onClick={() => setCanShowForm(true)}>
                            <button className="btn btn-primary" disabled={!accept}>
                                Continuar
                            </button>
                        </div>
                    </div>
            }
        </>
    )
}
export default CreateTravelForm;