import { Dropdown } from "react-bootstrap";
import { mainPermissions } from "../../util/MenuLinks";
import UserHavePermission from "../../util/userHavePermissions";
import useStatuses from "../../hooks/useStatuses";

const TravelsGlobalActions = ({ onDelete, onUpdateStatus }) => {

    const [{ statuses }] = useStatuses({ axiosConfig: {}, options: { useCache: false } });

    return (
        <Dropdown className="dropdown ms-auto text-right">
            <Dropdown.Toggle
                variant=""
                className="btn-link i-false"
                data-toggle="dropdown"
            >
                <svg
                    width="24px"
                    height="24px"
                    viewBox="0 0 24 24"
                    version="1.1"
                >
                    <g
                        stroke="none"
                        strokeWidth={1}
                        fill="none"
                        fillRule="evenodd"
                    >
                        <rect x={0} y={0} width={24} height={24} />
                        <circle fill="#000000" cx={5} cy={12} r={2} />
                        <circle fill="#000000" cx={12} cy={12} r={2} />
                        <circle fill="#000000" cx={19} cy={12} r={2} />
                    </g>
                </svg>
            </Dropdown.Toggle>
            <Dropdown.Menu className="dropdown-menu dropdown-menu-right">
                {
                    UserHavePermission(mainPermissions?.travels?.[1]) &&
                    <Dropdown.Item onClick={() => onDelete?.()}>
                        Eliminar
                    </Dropdown.Item>
                }
                {
                    statuses?.length > 0 &&
                    <>
                        <Dropdown.Divider />
                        {
                            statuses?.map((status, i) => {
                                return (
                                    <Dropdown.Item onClick={() => onUpdateStatus?.(status)}>
                                        Marcar como: <span className={`text-${status?.variantColor}`}>{status?.name}</span>
                                    </Dropdown.Item>
                                )
                            })
                        }
                    </>
                }
            </Dropdown.Menu>
        </Dropdown>
    )
}

export default TravelsGlobalActions;