import React from "react";
import { createContext, useContext, useEffect, useState } from "react";
import { useAuth } from "./AuthContext";

const TravelCrudContext = createContext({

});

export const TravelCrudProvider = ({ children }) => {

  const { user } = useAuth();

  const [data, setData] = useState({
    travel: 'no',
    travelBaggage: 'no',
    travelModality: 'aerea',
    travelStart: '',
    travelStartHour: '',
    travelStartFromCommune: '',
    travelStartToCommune: '',
    travelBack: false,
    travelEnd: '',
    travelEndHour: '',
    travelEndFromCommune: '',
    travelEndToCommune: '',
    travelPersons: [{
      name: user?.name,
      rut: user?.documentNumber,
      email: user?.email,
      phoneNumber: user?.phoneNumber,
      costCenter: user?.costCenter ? { label: user?.costCenter?.name, value: user?.costCenter?.id } : null,
      birthdate: user?.birthdate,
      chargeForFormulating: 'no'
    }],

    accommodation: 'no',
    accommodationStart: '',
    accommodationEnd: '',
    accommodationCommune: '',
    accommodationPersons: [{
      name: user?.name,
      rut: user?.documentNumber,
      email: user?.email,
      phoneNumber: user?.phoneNumber,
      costCenter: user?.costCenter ? { label: user?.costCenter?.name, value: user?.costCenter?.id } : null,
      subject: '',
      chargeForFormulating: 'no'
    }],
    accommodationObservations: '',

    taxi: 'no',
    taxiStart: '',
    taxiStartHour: '',
    taxiStartFrom: '',
    taxiStartFromPoint: '',
    taxiStartTo: '',
    taxiStartToPoint: '',
    taxiBack: false,
    taxiEnd: '',
    taxiEndHour: '',
    taxiEndFrom: '',
    taxiEndFromPoint: '',
    taxiEndTo: '',
    taxiEndToPoint: '',
    taxiObservations: '',
    taxiPersons: [{
      name: user?.name,
      rut: user?.documentNumber,
      email: user?.email,
      phoneNumber: user?.phoneNumber,
      costCenter: user?.costCenter ? { label: user?.costCenter?.name, value: user?.costCenter?.id } : null,
      subject: '',
      chargeForFormulating: 'no'
    }],
    reason: ''
  });

  const [canNext, setCanNext] = useState(false);

  const [currentStep, setCurrentStep] = useState(1);

  const [minDates, setMinDates] = useState(null);

  return <TravelCrudContext.Provider value={{
    data,
    setData,
    canNext,
    setCanNext,
    currentStep,
    setCurrentStep,
    minDates,
    setMinDates
  }}>
    {children}
  </TravelCrudContext.Provider>;
};

export const useTravelCrud = () => useContext(TravelCrudContext);
