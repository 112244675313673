import ActionDropdown from "../../ActionDropdown";
import TableCheck from "../TableCheck";
import { format } from "date-fns";
import RenderStatus from "../../RenderStatus";
import DateFormatter from "../../DateFormatter";
import ImageAndName from "../../ImageAndName";
import TravelStatusComponent from "../../TravelStatusComponent";
import userImg from "../../../images/profile.png";
import TravelsActionDropdown from "../../TravelsActionDropdown";

const MyTravelsColumns = [
    {
        Label: TableCheck,
        Component: TableCheck

    },
    {
        Label: () => 'id',
        accessor: 'id'
    },
    {
        Label: () => 'Solicitante',
        Component: ({ value }) => <ImageAndName imgValue={value?.user?.imagePath || userImg} nameValue={value?.user?.name} />
    },
    {
        Label: () => 'Centro de costo',
        Component: ({ value }) => `${value?.user?.costCenter?.name || '--'}`
    },
    {
        Label: () => 'Viaje',
        Component: ({ value }) => `${value?.travel}`
    },
    {
        Label: () => 'Destino',
        Component: ({ value }) => `${value?.travelStartToCommune?.name || "--"}`
    },
    {
        Label: () => 'Hospejade',
        Component: ({ value }) => `${value?.accommodation}`
    },
    {
        Label: () => 'Taxi',
        Component: ({ value }) => `${value?.taxi}`
    },
    {
        Label: () => 'Estatus',
        Component: TravelStatusComponent
    },
    {
        Label: () => 'Fecha de Creación',
        Component: ({ value }) => value?.createdAt
    },
    {
        Label: () => 'Acciones',
        Component: TravelsActionDropdown
    }
];

export default MyTravelsColumns;

