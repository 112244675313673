import { useEffect, useState } from "react";
import { Button, Modal, Table } from "react-bootstrap";
import dateOnlyConverter from "../util/dateOnlyConverter";
import DateFormatter from "./DateFormatter";
import { useFeedBack } from "../context/FeedBackContext";
import useCommunes from "../hooks/useCommunes";
import useAxios from "../hooks/useAxios";
import { MdEdit } from "react-icons/md";
import mapValues from "../util/mapValues";
import handleLoadSelectOptions from "../util/loadSelectValues";
import AsyncSelect from "react-select/async";

const AccommodationSummary = ({ travel, onExport, loadingExport, onUpdate, canEdit }) => {

  const { setCustomAlert } = useFeedBack();

  const [showPersonsModal, setShowPersonsModal] = useState(false);

  const [showModal, setShowModal] = useState(false);

  const [valuesToEdit, setValuesToEdit] = useState({
    start: '',
    end: '',
    commune: ''
  });

  const [{ communes, loading: communesLoading }, getCommunes] = useCommunes({
    params: { perPage: 100 }, options: { manual: true, useCache: false },
  });

  const [{ data, loading }, updateValues] = useAxios({ url: `/travels/${travel?.id}/accommodation`, method: 'PUT' }, { manual: true, useCache: false });

  useEffect(() => {
    if (data) {
      setCustomAlert({
        show: true,
        message: "El registro ha sido actualizado exitosamente.",
        severity: "success",
        title: "Operación Exitosa",
      });
      onUpdate?.(data?.data);
      setShowModal(false);
    }
  }, [data])

  if (travel?.accommodation !== "si") return null;

  const handleClose = () => {
    setShowPersonsModal((oldValue) => !oldValue);
  };

  const handleEdit = () => {
    setValuesToEdit({
      start: DateFormatter({
        value: dateOnlyConverter(travel?.accommodationStart),
        dateFormat: "yyyy-MM-dd",
      }),
      end: DateFormatter({
        value: dateOnlyConverter(travel?.accommodationEnd),
        dateFormat: "yyyy-MM-dd",
      }),
      commune: { label: travel?.accommodationCommune?.name, value: travel?.accommodationCommune?.id },
    });

    setShowModal(true);
  }

  const handleChange = (e) => {
    setValuesToEdit((oldValues) => {
      return {
        ...oldValues,
        [e.target.name]: e.target.value
      }
    });
  }

  const handleSubmit = (e) => {
    e?.preventDefault?.();

    updateValues({
      data: {
        ...valuesToEdit,
        commune: valuesToEdit?.commune?.value
      }
    });
  }

  return (
    <>
      <div
        className="card"
        style={{ borderTopLeftRadius: 0, borderTopRightRadius: 0 }}
      >
        <div className="card-header">
          <div className="d-flex align-items-center justify-content-between w-100">
            <h5>🏨 Datos del servicio del Hotel</h5>
            <button
              className="btn btn-xs btn-success"
              onClick={() => onExport?.("accommodation", "hospedaje")}
            >
              {loadingExport ? "cargando..." : "Exportar a excel"}
            </button>
          </div>
        </div>
        <div className="card-body">
          <div className="row">
            <div className="col-md-6 mb-3">
              <label className="text-primary">Usuario Solicitante</label>
              <input
                readOnly
                type="text"
                value={travel?.user?.name || "--"}
                className="form-control"
              />
            </div>
            <div className="col-md-6 mb-3">
              <label className="text-primary d-flex justify-content-between align-items-center">
                Número de personas
                <span
                  type="button"
                  className="text-primary"
                  onClick={handleClose}
                >
                  Ver Detalles
                </span>
              </label>
              <input
                readOnly
                type="text"
                value={travel?.accommodationPersons?.length}
                className="form-control"
              />
            </div>
            <div className="col-md-6 mb-3">
              <label className="text-primary">Check in</label>
              <input
                readOnly
                type="text"
                value={DateFormatter({
                  value: dateOnlyConverter(travel?.accommodationStart),
                  dateFormat: "d/MM/yyyy",
                })}
                className="form-control"
              />
            </div>
            <div className="col-md-6 mb-3">
              <label className="text-primary">Check out</label>
              <input
                readOnly
                type="text"
                value={DateFormatter({
                  value: dateOnlyConverter(travel?.accommodationEnd),
                  dateFormat: "d/MM/yyyy",
                })}
                className="form-control"
              />
            </div>
            <div className="col-md-12 mb-3">
              <label className="text-primary">Comuna</label>
              <input
                readOnly
                type="text"
                value={travel?.accommodationCommune?.name}
                className="form-control"
              />
            </div>
            <div className="col-md-12 mb-3">
              <label className="text-primary">Sugerencias de estadia</label>
              <textarea
                rows={4}
                style={{ minHeight: 150 }}
                readOnly
                type="text"
                value={travel?.accommodationObservations}
                className="form-control"
              />
            </div>
            {
              canEdit &&
              <div className="col-md-12 mb-3 text-center">
                <button onClick={handleEdit} className="btn btn-primary">
                  Editar  <MdEdit />
                </button>
              </div>
            }
          </div>
        </div>
      </div>

      <Modal show={showPersonsModal} onHide={handleClose} size="lg">
        <Modal.Header closeButton>
          <Modal.Title>Detalle de las personas</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <div className="table-responsive">
            <Table striped>
              <thead>
                <tr>
                  <th style={{ fontSize: 12 }}>#</th>
                  <th style={{ fontSize: 12 }}>Nombre</th>
                  <th style={{ fontSize: 12 }}>Rut</th>
                  <th style={{ fontSize: 12 }}>Teléfono</th>
                  <th style={{ fontSize: 12 }}>Email</th>
                  <th style={{ fontSize: 12 }}>Centro de costo</th>
                  <th style={{ fontSize: 12 }}>Cobro por formular</th>
                  <th style={{ fontSize: 12 }}>Motivo</th>
                </tr>
              </thead>
              <tbody>
                {travel?.accommodationPersons?.map((person, i) => {
                  return (
                    <tr>
                      <td>{i + 1}</td>
                      <td>{person?.name || "--"}</td>
                      <td>{person?.rut || "--"}</td>
                      <td>{person?.phoneNumber || "--"}</td>
                      <td>{person?.email || "--"}</td>
                      <td>{person?.costCenter?.name || "--"}</td>
                      <td style={{ textTransform: 'capitalize' }}>{person?.chargeForFormulating || "--"}</td>
                      <td>{person?.subject || "--"}</td>
                    </tr>
                  );
                })}
              </tbody>
            </Table>
          </div>
        </Modal.Body>
        <Modal.Footer className="text-center justify-content-center">
          <Button variant="danger" onClick={handleClose}>
            Cerrar
          </Button>
        </Modal.Footer>
      </Modal>
      <Modal show={showModal} onHide={() => setShowModal(false)} size="xl">
        <Modal.Header closeButton>
          <Modal.Title>Editar datos de la estadia</Modal.Title>
        </Modal.Header>
        <form onSubmit={handleSubmit}>
          <Modal.Body>
            <div className="row">
              <div className="col-md-6 mb-3">
                <label className="text-primary">
                  Check In
                  <small className="text-danger">*</small>
                </label>
                <input
                  type="date"
                  value={valuesToEdit?.start}
                  className="form-control"
                  name="start"
                  required
                  onChange={handleChange}
                />
              </div>
              <div className="col-md-6 mb-3">
                <label className="text-primary">
                  Check Out
                  <small className="text-danger">*</small>
                </label>
                <input
                  type="date"
                  name="end"
                  onChange={handleChange}
                  value={valuesToEdit.end}
                  required
                  className="form-control"
                />
              </div>
              <div className="col-md-12 mb-3">
                <label className="text-primary">
                  Communa
                  <small className="text-danger">*</small>
                </label>
                <AsyncSelect
                  onFocus={() => {
                    getCommunes({
                      params: { perPage: 100 }
                    });
                  }}
                  value={valuesToEdit?.commune}
                  defaultOptions={mapValues(communes)}
                  isLoading={communesLoading}
                  loadOptions={(e) =>
                    handleLoadSelectOptions(e, getCommunes, { perPage: 100 })
                  }
                  placeholder="buscar..."
                  onChange={(e) => {
                    handleChange({
                      target: {
                        value: e,
                        name: "commune",
                      },
                    });
                  }}
                />
              </div>
            </div>
          </Modal.Body>
          <Modal.Footer>
            <div className="row align-items-center w-100">
              <div className="col-sm-6 mb-3 mb-sm-0">
                <button className="btn btn-danger btn-block" type="button" variant="danger" onClick={() => setShowModal(false)}>
                  Cancelar
                </button>
              </div>
              <div className="col-sm-6 mb-3 mb-sm-0">
                <button disabled={loading} className="btn btn-primary btn-block" variant="primary" >
                  {
                    loading ?
                      <div className="spinner" style={{ width: 21, height: 21 }}>
                        <div className="double-bounce1 bg-light"></div>
                        <div className="double-bounce2 bg-light"></div>
                      </div>
                      :
                      'Enviar'
                  }
                </button>
              </div>
            </div>
          </Modal.Footer>
        </form>
      </Modal>
    </>
  );
};

export default AccommodationSummary;
