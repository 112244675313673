import { useEffect, useState } from "react";
import { useSearchParams } from "react-router-dom";
import CustomTable from "../../../components/CustomTable/CustomTable";
import { useFeedBack } from "../../../context/FeedBackContext";
import TravelExceptionsGlobalActions from "../../../components/TravelExceptionsGlobalActions";
import useAxios from "../../../hooks/useAxios";
import TravelExceptionColumns from "../../../components/CustomTable/Columns/TravelExceptionColumns";

const ExceptionsHistory = () => {

    let [searchParams, setSearchParams] = useSearchParams();

    const { setCustomAlert, setLoading } = useFeedBack();

    const [filters, setFilters] = useState({
        page: 1,
        name: '',
        email: '',
        document_number: '',
        active: '',
        used: '',
        start: '',
        end: ''
    });

    const [selectedValues, setSelectedValues] = useState([]);

    const [selectAll, setSelectAll] = useState(false);

    const [{ data, loading }, getRecords] = useAxios({ url: `/travel-exceptions`, params: filters }, { useCache: false });

    useEffect(() => {
        var defaultFilters = { ...filters };
        Object.keys(filters).map((keyName) => {
            if (searchParams.get(keyName)) {
                defaultFilters[keyName] = searchParams.get(keyName) ?? '';
            }
        });
        setFilters(defaultFilters);
    }, [])

    useEffect(() => {
        if (selectAll) {
            setSelectedValues(data?.data?.map?.((value) => value?.id))
        } else {
            setSelectedValues([])
        }
    }, [selectAll])

    const handleSelectALL = () => {
        setSelectAll((oldSelectAll) => !oldSelectAll);
    }

    const handleSelectValue = (selectedValue) => {
        const value = selectedValues?.includes(Number(selectedValue?.id));
        if (value) {
            const newValues = selectedValues?.filter(n => n !== Number(selectedValue?.id));
            setSelectedValues(newValues);
        } else {
            setSelectedValues((oldSelectedValues) => [...oldSelectedValues, Number(selectedValue?.id)])
        }
    }

    const handleChange = (e) => {
        setFilters((oldFilters) => {
            return {
                ...oldFilters,
                [e.target.name]: e.target.value,
                page: 1
            }
        })
    }

    const handleUpdateException = () => {
        getRecords();
    }

    return (
        <div>
            <div className="row">
                <div className="col-md-4">
                    <div className="card p-4">
                        <div className="form-group">
                            <label className="text-primary">Nombre del trabajador</label>
                            <input
                                type="text"
                                className="form-control"
                                value={filters?.name}
                                onChange={handleChange}
                                name="name"
                                placeholder="name"
                            />
                        </div>
                    </div>
                </div>
                <div className="col-md-4">
                    <div className="card p-4">
                        <div className="form-group">
                            <label className="text-primary">Email del trabajador</label>
                            <input
                                type="text"
                                className="form-control"
                                value={filters?.email}
                                onChange={handleChange}
                                name="email"
                                placeholder="email"
                            />
                        </div>
                    </div>
                </div>
                <div className="col-md-4">
                    <div className="card p-4">
                        <div className="form-group">
                            <label className="text-primary">RUT</label>
                            <input
                                type="text"
                                className="form-control"
                                value={filters?.document_number}
                                onChange={handleChange}
                                name="document_number"
                                placeholder="Rut del trabajador"
                            />
                        </div>
                    </div>
                </div>
                <div className="col-md-3">
                    <div className="card p-4">
                        <div className="form-group">
                            <label className="text-primary">Ver solo:</label>
                            <select name="active" className="form-control" value={filters?.active} onChange={handleChange}>
                                <option value="">Todas</option>
                                <option value="si">Solo activas</option>
                                <option value="no">Solo inactivas</option>
                            </select>
                        </div>
                    </div>
                </div>
                <div className="col-md-3">
                    <div className="card p-4">
                        <div className="form-group">
                            <label className="text-primary">Ver solo:</label>
                            <select name="used" className="form-control" value={filters?.used} onChange={handleChange}>
                                <option value="">Todas</option>
                                <option value="si">Solo las usadas</option>
                                <option value="no">Solo las no usadas</option>
                            </select>
                        </div>
                    </div>
                </div>
                <div className="col-md-6">
                    <div className="card p-4">
                        <div className="row">
                            <div className="col-md-6 mb-3">
                                <div className="form-group">
                                    <label className="text-primary">Desde:</label>
                                    <input
                                        className="form-control"
                                        type="date"
                                        name="start"
                                        value={filters?.start}
                                        onChange={handleChange}
                                    />
                                </div>
                            </div>
                            <div className="col-md-6 mb-3">
                                <div className="form-group">
                                    <label className="text-primary">Hasta:</label>
                                    <input
                                        className="form-control"
                                        type="date"
                                        name="end"
                                        value={filters?.end}
                                        onChange={handleChange}
                                    />
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            <CustomTable
                //CustomGlobalActionComponent={<TravelExceptionsGlobalActions selectedValues={selectedValues} onUpdateException={handleUpdateException} />}
                excelUrl={`/travel-exceptions/export/excel`}
                withoutGlobalActions
                onSelectValue={handleSelectValue}
                onSelectAll={handleSelectALL}
                loading={loading}
                selectAll={selectAll}
                title={'Historial de excepciones'}
                selectedValues={selectedValues}
                pages={data}
                total={data?.meta?.total}
                values={data?.data}
                currentPage={filters?.page}
                collumns={TravelExceptionColumns}
                changePage={(page) => {
                    if (page <= data?.meta?.last_page && page > 0) {
                        setFilters((oldFilters) => {
                            return {
                                ...oldFilters,
                                page: page
                            }
                        })
                    }
                }}
                onPerPageChange={handleChange}
                perPage={filters?.perPage}
            />
        </div>
    )
}

export default ExceptionsHistory;