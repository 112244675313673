import { useTravelCrud } from "../../../../context/TravelCrudContext";
import hotelImage from "../../../../images/hotel.png";
import grayHotelImage from "../../../../images/hotel-gris.png";
import { useState, useEffect } from "react";
import AsyncSelect from "react-select/async";
import useCommunes from "../../../../hooks/useCommunes";
import mapValues from "../../../../util/mapValues";
import handleLoadSelectOptions from "../../../../util/loadSelectValues";
import update from "immutability-helper";
import useCostCenters from "../../../../hooks/useCostCenters";
import plantillaExcel from "../../../../documents/plantilla-personas-para-hospedajes.xlsx";
import useAxios from "../../../../hooks/useAxios";
import readXlsxFile from 'read-excel-file';

const StepThree = () => {
  const { data, setData, currentStep, setCurrentStep, minDates } = useTravelCrud();

  const [canContinue, setCanContinue] = useState(false);



  const [{ communes, loading: communesLoading }, getCommunes] = useCommunes({
    options: { manual: true, useCache: false },
  });

  const [{ }, getCostCenter] = useAxios({ method: 'GET' }, { manual: true, useCache: true });

  const [{ costCenters, loading: costCentersLoading }, getCostCenters] = useCostCenters({ options: { manual: true, useCache: false } });

  useEffect(() => {
    if (data.accommodationStart && !data.travelStart) setData(oldData => ({ ...oldData, travelStart: data.accommodationStart }))
    if (data.accommodationStart && !data.taxiStart) setData(oldData => ({ ...oldData, taxiStart: data.accommodationStart }))

    if (data.accommodationEnd && !data.travelEnd) setData(oldData => ({ ...oldData, travelEnd: data.accommodationEnd }));
    if (data.accommodationEnd && !data.taxiEnd) setData(oldData => ({ ...oldData, taxiEnd: data.travelEnd }));

  }, [data.accommodationStart, data.accommodationEnd]);

  useEffect(() => {

    if (data.accommodationCommune && !data.travelStartToCommune) setData(oldData => ({ ...oldData, travelStartToCommune: data.accommodationCommune }))

    if (data.accommodationCommune && !data.travelEndFromCommune) setData(oldData => ({ ...oldData, travelEndFromCommune: data.accommodationCommune }))

  }, [data.accommodationCommune])

  useEffect(() => {
    if (data.accommodation === "si") {
      if (
        data.accommodationStart &&
        data.accommodationEnd &&
        data.accommodationCommune &&
        data.accommodationPersons.length > 0
      ) {
        setCanContinue(true);
      } else {
        setCanContinue(false);
      }
    }

    if (data?.accommodation === "no") {
      setCanContinue(true);
    }
  }, [data]);

  const handleChange = (e) => {
    setData((oldData) => {
      return {
        ...oldData,
        [e.target.name]: e.target.value,
      };
    });
  };

  const removeFromArray = (index, arrayName) => {
    data?.[arrayName]?.splice(index, 1);

    setData((oldData) => {
      return {
        ...oldData,
        [arrayName]: data?.[arrayName],
      };
    });
  };

  const handleArrayChange = (e, index, arrayName) => {
    var newArrayValues = [];
    if (e.target.name === "images") {
      newArrayValues = update(data?.[arrayName], {
        [index]: { $set: e.target.files[0] },
      });
    } else {
      newArrayValues = update(data?.[arrayName], {
        [index]: {
          [e.target.name]: {
            $set: e.target.type === "file" ? e.target.files[0] : e.target.value,
          },
        },
      });
    }
    setData((oldData) => {
      return {
        ...oldData,
        [arrayName]: newArrayValues,
      };
    });
  };

  const handleRead = (e) => {
    readXlsxFile(e.target.files[0]).then(async (rows) => {
      var response = null;
      for (let index = 0; index < rows.length; index++) {
        const row = rows[index];
        if (index > 0) {
          try {
            if (!response) {
              response = await getCostCenter({ url: `cost-centers/get-by-code`, params: { code: row?.[4] } });
              console.log('consulte la primera');
            }
            if (index > 1 && rows?.[index - 1]?.[4] && response?.data?.code != rows?.[index - 1]?.[4]) {
              response = await getCostCenter({ url: `cost-centers/get-by-code`, params: { code: row?.[4] } });
              console.log('consulte la segunda');
            }

            setData((oldData) => {
              return {
                ...oldData,
                accommodationPersons: [
                  ...oldData?.accommodationPersons,
                  {
                    name: row[0],
                    rut: row[1],
                    email: row[2],
                    phoneNumber: row[3],
                    costCenter: { label: response?.data?.name, value: response?.data?.id },
                    subject: row[5],
                    chargeForFormulating: row[6]
                  }
                ]
              }
            })

          } catch (error) {

          }
        }
      }
    })
  }

  return (
    <div className="container">
      <div className="card col-md-12 animate__animated animate__fadeInUp p-4">
        <div className="card-header">
          <h4>Información sobre el alojamiento</h4>
        </div>
        <div className="card-body">
          <div className="row">
            <div className="form-group mb-3 col-md-12">
              <h3>¿Necesitas hospedaje?</h3>
              <div className="row align-items-center">
                <div className="col-md-6">
                  <div className="text-center">
                    <label
                      onClick={() =>
                        handleChange({
                          target: { name: "accommodation", value: "no" },
                        })
                      }
                      htmlFor="togglesome"
                      className="text-center mt-5 animate__animated animate__fadeIn"
                      style={{ width: "fit-content", cursor: "pointer" }}
                    >
                      <img src={grayHotelImage} style={{ width: "170px" }} />
                      <h3>
                        <input
                          className="mx-2"
                          type="radio"
                          checked={data.accommodation === "no"}
                          onChange={() => null}
                        />
                        No
                      </h3>
                    </label>
                  </div>
                </div>
                <div className="col-md-6">
                  <div className="text-center">
                    <label
                      onClick={() =>
                        handleChange({
                          target: { name: "accommodation", value: "si" },
                        })
                      }
                      htmlFor="togglesome"
                      className="text-center mt-5 animate__animated animate__fadeIn"
                      style={{ width: "fit-content", cursor: "pointer" }}
                    >
                      <img src={hotelImage} style={{ width: "170px" }} />
                      <h3>
                        <input
                          className="mx-2"
                          type="radio"
                          checked={data?.accommodation === "si"}
                          onChange={() => null}
                        />
                        Si
                      </h3>
                    </label>
                  </div>
                </div>
                {data.accommodation === "si" && (
                  <div className="col-md-12 my-5 animate__animated animate__fadeInLeft">
                    <h3 className="text-center mb-3">Datos del alojamiento</h3>
                    <div className="row">
                      <div className="col-md-6">
                        <div className="row">
                          <div className="col-md-6">
                            <label>Fecha de ingreso</label>
                            <input
                              type="date"
                              name="accommodationStart"
                              onChange={handleChange}
                              value={data.accommodationStart}
                              required
                              min={minDates?.accommodation}
                              className="form-control"
                            />
                          </div>
                          <div className="col-md-6">
                            <label>Fecha de salida</label>
                            <input
                              type="date"
                              name="accommodationEnd"
                              onChange={handleChange}
                              value={data.accommodationEnd}
                              required
                              min={data?.accommodationStart || minDates?.accommodation || null}
                              className="form-control"
                            />
                          </div>
                        </div>
                      </div>
                      <div className="col-md-6">
                        <label>Comuna</label>
                        <AsyncSelect
                          onFocus={() => {
                            getCommunes();
                          }}
                          value={data?.accommodationCommune}
                          defaultOptions={mapValues(communes)}
                          isLoading={communesLoading}
                          loadOptions={(e) =>
                            handleLoadSelectOptions(e, getCommunes)
                          }
                          placeholder="buscar..."
                          onChange={(e) => {
                            handleChange({
                              target: {
                                value: e,
                                name: "accommodationCommune",
                              },
                            });
                          }}
                        />
                      </div>
                      <div className="col-md-12 mb-4 mt-5">
                        <h3 className="text-center">Personas ha hospedar</h3>
                        <div className="text-center">
                          <a href={plantillaExcel} download="plantilla-personas-para-hospedajes.xlsx">
                            Descargar plantilla
                          </a>
                          <br />
                          <label htmlFor="upload-file" className="btn btn-success">
                            <input id="upload-file" accept="application/vnd.openxmlformats-officedocument.spreadsheetml.sheet, application/vnd.ms-excel" type="file" onChange={handleRead} className="d-none" />
                            Cargar desde archivo
                          </label>
                        </div>
                        <div className="table-responsive">
                          <table
                            className="table"
                            style={{ minWidth: "600px" }}
                          >
                            <tbody>
                              {data?.accommodationPersons?.map((person, i) => {
                                return (
                                  <tr key={i}>
                                    <td>
                                      <label>
                                        <small className="text-primary">
                                          Nombre{" "}
                                          <span className="text-danger">*</span>
                                        </small>
                                      </label>
                                      <input
                                        onChange={(e) =>
                                          handleArrayChange(
                                            e,
                                            i,
                                            "accommodationPersons"
                                          )
                                        }
                                        value={person?.name}
                                        name="name"
                                        type="text"
                                        className="form-control"
                                        placeholder="Nombre..."
                                      />
                                    </td>
                                    <td>
                                      <label>
                                        <small className="text-primary">
                                          Rut{" "}
                                          <span className="text-danger">*</span>
                                        </small>
                                      </label>
                                      <input
                                        onChange={(e) =>
                                          handleArrayChange(
                                            e,
                                            i,
                                            "accommodationPersons"
                                          )
                                        }
                                        value={person?.rut}
                                        name="rut"
                                        type="text"
                                        className="form-control"
                                        placeholder="Rut..."
                                      />
                                    </td>
                                    <td>
                                      <label>
                                        <small className="text-primary">
                                          Email{" "}
                                          <span className="text-danger">*</span>
                                        </small>
                                      </label>
                                      <input
                                        onChange={(e) =>
                                          handleArrayChange(
                                            e,
                                            i,
                                            "accommodationPersons"
                                          )
                                        }
                                        value={person.email}
                                        name="email"
                                        type="text"
                                        className="form-control"
                                        placeholder="Email..."
                                      />
                                    </td>
                                    <td>
                                      <label>
                                        <small className="text-primary">
                                          Teléfono{" "}
                                          <span className="text-danger">*</span>
                                        </small>
                                      </label>
                                      <input
                                        onChange={(e) =>
                                          handleArrayChange(
                                            e,
                                            i,
                                            "accommodationPersons"
                                          )
                                        }
                                        value={person.phoneNumber}
                                        name="phoneNumber"
                                        type="text"
                                        className="form-control"
                                        placeholder="Telefono..."
                                      />
                                    </td>
                                    <td>
                                      <label>
                                        <small className="text-primary">
                                          Centro de costo{" "}
                                          <span className="text-danger">*</span>
                                        </small>
                                      </label>
                                      <AsyncSelect
                                        onFocus={() => {
                                          getCostCenters();
                                        }}
                                        defaultOptions={mapValues(costCenters)}
                                        value={person.costCenter}
                                        isLoading={costCentersLoading}
                                        loadOptions={(e) =>
                                          handleLoadSelectOptions(
                                            e,
                                            getCostCenters
                                          )
                                        }
                                        placeholder="buscar..."
                                        onChange={(e) =>
                                          handleArrayChange(
                                            {
                                              target: {
                                                value: e,
                                                name: "costCenter",
                                              },
                                            },
                                            i,
                                            "accommodationPersons"
                                          )
                                        }
                                      />
                                    </td>
                                    <td>
                                      <label>
                                        <small className="text-primary">
                                          Motivo
                                        </small>
                                      </label>
                                      <input
                                        onChange={(e) =>
                                          handleArrayChange(
                                            e,
                                            i,
                                            "accommodationPersons"
                                          )
                                        }
                                        value={person?.subject}
                                        name="subject"
                                        type="text"
                                        className="form-control"
                                        placeholder="Motivo..."
                                      />
                                    </td>
                                    <td>
                                      <label>
                                        <small className="text-primary">
                                          Cobro por Formular{" "}
                                          <span className="text-danger">*</span>
                                        </small>
                                      </label>
                                      <select className="form-control"
                                        value={person?.chargeForFormulating}
                                        name="chargeForFormulating"
                                        onChange={(e) =>
                                          handleArrayChange(e, i, "accommodationPersons")
                                        }
                                      >
                                        <option value="no">No</option>
                                        <option value="si">Si</option>
                                      </select>
                                    </td>
                                    <td>
                                      <div className="d-flex align-items-center">
                                        <button
                                          onClick={() =>
                                            removeFromArray(
                                              i,
                                              "accommodationPersons"
                                            )
                                          }
                                          title="Remover Persona"
                                          className="btn btn-xs btn-danger"
                                        >
                                          X
                                        </button>
                                      </div>
                                    </td>
                                  </tr>
                                );
                              })}
                              <tr>
                                <td colSpan={7}>
                                  <div className="text-center">
                                    <button
                                      onClick={() => {
                                        setData((oldData) => {
                                          return {
                                            ...oldData,
                                            accommodationPersons: [],
                                          };
                                        });
                                      }}
                                      className="btn btn-xs btn-danger"
                                    >
                                      Remover Todas
                                    </button>
                                    <button
                                      onClick={() => {
                                        setData((oldData) => {
                                          return {
                                            ...oldData,
                                            accommodationPersons: [
                                              ...oldData?.accommodationPersons,
                                              {
                                                name: "",
                                                rut: "",
                                                email: "",
                                                phoneNumber: "",
                                                costCenter: "",
                                                subject: "",
                                                chargeForFormulating: 'no'
                                              },
                                            ],
                                          };
                                        });
                                      }}
                                      className="btn btn-xs btn-primary"
                                    >
                                      Agregar Persona
                                    </button>
                                  </div>
                                </td>
                              </tr>
                            </tbody>
                          </table>
                        </div>
                      </div>
                      <div className="col-md-12 mt-3">
                        <label htmlFor="">Sugerencias para su estadía</label>
                        <textarea
                          name="accommodationObservations"
                          value={data?.accommodationObservations}
                          style={{
                            minHeight: "150px",
                          }}
                          className="form-control"
                          onChange={handleChange}
                        />
                      </div>
                    </div>
                  </div>
                )}
              </div>
            </div>
          </div>
        </div>
        <div className="card-footer text-end">
          <button
            type="button"
            className="btn btn-danger mx-1"
            onClick={() =>
              setCurrentStep((oldStep) => {
                return oldStep - 1;
              })
            }
          >
            Atras
          </button>
          <button
            disabled={!canContinue}
            type="button"
            className="btn btn-primary mx-1"
            onClick={() => {
              setCurrentStep((oldStep) => oldStep + 1);
            }}
          >
            Siguiente
          </button>
        </div>
      </div>
    </div>
  );
};

export default StepThree;
