import TableCheck from "../TableCheck";
import ImageAndName from "../../ImageAndName";
import userImg from "../../../images/profile.png";
import TravelExceptionToggleComponent from "../../TravelExceptionToggleComponent";

const ExceptionColumns = [
    {
        Label: TableCheck,
        Component: TableCheck
    },
    {
        Label: () => 'id',
        accessor: 'id'
    },
    {
        Label: () => 'Solicitante',
        Component: ({ value }) => <ImageAndName imgValue={value?.imagePath || userImg} nameValue={value?.name} />
    },
    {
        Label: () => 'Correo Electrónico',
        Component: ({ value }) => `${value?.email}`
    },
    {
        Label: () => 'Rut',
        Component: ({ value }) => `${value?.documentNumber || '--'}`
    },
    {
        Label: () => 'Excepción',
        Component: TravelExceptionToggleComponent
    }
];

export default ExceptionColumns;

